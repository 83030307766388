import fetch from '../fetch'

export const sendCodeToXServicerByGet = (params, isVersionNew) => {
  const url = `${window.__HOST_CONFIG__.xHost}/sso_token${isVersionNew ? '_v1' : ''}`
  return fetch({
    noValidate: true,
    method: 'get',
    url,
    params,
  })
}
