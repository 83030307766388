import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Button from '@/components/common/Button/Button'
import Link from '@/components/common/router/Link'
import TitleBar from '@/components/common/TitleBar/Index'
import CollectionList from './List'

import style from './index.module.scss'
const Collections = ({ className, titleClass, title, path = 'collections', columns = 7 }) => {
  const topics = useSelector((state) => state.app.topics)
  return (
    <CollectionList
      className={classNames([className])}
      columns={columns}
      list={topics}
      customTitleRender={
        <TitleBar
          titleId={title}
          titleClass={titleClass}
          renderRight={
            topics.length <= columns ? null : (
              <Link
                to={{
                  pathname: path,
                }}
              >
                <Button type="primaryChange" className={style.linkButton}>
                  <FormattedMessage id="viewAll"></FormattedMessage>
                </Button>
              </Link>
            )
          }
        />
      }
    />
  )
}

export default React.memo(Collections)
