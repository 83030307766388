import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import TitleBarLayout from '@/components/layout/titleBar/Index'
import CollectionItem from './Item'
import bgColorList from './bgColor'
import style from './index.module.scss'
import classNames from 'classnames'
const CollectionList = ({
  className,
  styleObj = {},
  columns = 7,
  list = [],
  customTitleRender,
}) => {
  const history = useHistory()

  const onItemClick = useCallback(
    (topic) => {
      history.push({
        pathname: '/byTopicApps',
        search: `topicId=${topic.id}`,
      })
    },
    [history]
  )

  if (list.length === 0) {
    return null
  }

  return (
    <TitleBarLayout
      className={classNames([style.container, className])}
      customTitleRender={customTitleRender}
      columns={columns}
      list={list}
      keyId="topic_name"
    >
      {(item, index) => {
        return (
          <CollectionItem
            styleObj={{ ...styleObj, ...bgColorList[index % bgColorList.length] }}
            topic={item}
            onClick={onItemClick}
          />
        )
      }}
    </TitleBarLayout>
  )
}

export default React.memo(CollectionList)
