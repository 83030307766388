import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Lazy, Virtual, Navigation } from 'swiper'
import classNames from 'classnames'
import TitleBar from '@/components/common/TitleBar/Index'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import RcTooltip from '@/components/tooltip/RcTooltip'
import Button from '@/components/common/Button/Button'
import Link from '@/components/common/router/Link'
import style from './index.module.scss'
import Utils from '@/Utils'
import { useMemo } from 'react'
SwiperCore.use([Virtual, Navigation, Lazy])
const CarouselLayout = ({
  className,
  titleId,
  title,
  titleClass,
  linkTo,
  pagination = true,
  onRefresh,
  list,
  linkToText = 'viewAll',
  carouselKeyId = 'carousel',
  slideConfig = {},
  slidesPerView = 4,
  slidesPerGroup = slidesPerView,
  children,
  customTitleRender,
  upOnePage,
  lazyOptions = {
    loadPrevNext: true,
    loadPrevNextAmount: slidesPerView,
    elementClass: 'imageImg',
  },
}) => {
  const nextElClassName = `swiper-button-next_${carouselKeyId}`
  const prevElClassName = `swiper-button-prev_${carouselKeyId}`

  // swiper配置参数
  const settings = useMemo(() => {
    return {
      simulateTouch: false,
      spaceBetween: Utils.getResponseSise(20),
      navigation: {
        nextEl: `.${nextElClassName}`,
        prevEl: `.${prevElClassName}`,
        disabledClass: style.disabled,
      },
      slidesPerView,
      slidesPerGroup,
      lazy: lazyOptions,
    }
  }, [slidesPerView, prevElClassName, nextElClassName, slidesPerGroup, lazyOptions])

  const renderTitleLeftJsx = useMemo(() => {
    if (onRefresh && upOnePage) {
      return (
        <RcTooltip
          placement="right"
          key="cloudButtonTooltip"
          trigger="hover"
          overlay={
            <span>
              <FormattedMessage id="shuffleTip"></FormattedMessage>
            </span>
          }
        >
          <SvgIcon
            onClick={onRefresh}
            key="next"
            size={1}
            icon="shuffle"
            className={style.reFreshIcon}
          />
        </RcTooltip>
      )
    }
    return null
  }, [onRefresh, upOnePage])

  // header渲染器
  const renderTitleRightJsx = useMemo(() => {
    return (
      <div className={style.headerLeftWrap}>
        {pagination ? (
          <div className={style.switchContainer}>
            <SvgIcon
              key="prev"
              size={1}
              icon="arrow-left-circle"
              className={[style.arrowLeft, prevElClassName]}
            />
            <SvgIcon
              key="next"
              size={1}
              icon="arrow-right-circle"
              className={[style.arrowRight, nextElClassName]}
            />
          </div>
        ) : null}
        {linkTo && upOnePage ? (
          <Link key="link" to={linkTo}>
            <Button type="primaryChange" className={style.linkButton}>
              <FormattedMessage id={linkToText}></FormattedMessage>
            </Button>
          </Link>
        ) : null}
      </div>
    )
  }, [prevElClassName, nextElClassName, linkTo, linkToText, pagination, upOnePage])
  return (
    <div className={classNames([style.container, className])}>
      {(customTitleRender &&
        (typeof customTitleRender === 'function' ? customTitleRender() : customTitleRender)) || (
        <TitleBar
          titleId={titleId}
          title={title}
          titleClass={titleClass}
          renderRight={renderTitleRightJsx}
          renderLeft={renderTitleLeftJsx}
        />
      )}
      <Swiper {...settings} {...slideConfig} className={style.content}>
        {list.map((banner, index) => {
          return (
            <SwiperSlide virtualIndex={index} key={index}>
              {(slideOptions) => children(banner, index, slideOptions)}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default React.memo(CarouselLayout)
