// 获取系统信息
export const FETCH_SYSTEM_INFO = 'FETCH_SYSTEM_INFO'

// 切换无网络页面显示
export const SHOW_APP_OFFLINE_PAGE = 'SHOW_APP_OFFLINE_PAGE'

// 设置网络状态
export const SET_IS_ONLINE = 'SET_IS_ONLINE'

// 设置vm弹框
export const SET_VM_POPUP = 'SET_VM_POPUP'

// 设置loader
export const SWITCH_LOADER = 'SWITCH_LOADER'

// 设置是否boot完成
export const IS_BOOT_COMPLETE = 'IS_BOOT_COMPLETE'

// 国家码
export const SET_APP_COUNTRY_CODE = 'SET_APP_COUNTRY_CODE'

// 区域码<-->语言
export const SET_APP_LOCAL_CODE = 'SET_APP_LOCAL_CODE'

// 页面位置
export const SET_APP_LOCATION_PATH = 'SET_APP_LOCATION_PATH'

// 客户端config
export const SET_APP_CLIENT_CONFIG = 'SET_APP_CLIENT_CONFIG'

// 系统模式
export const SET_APP_CLIENT_MODE = 'SET_APP_CLIENT_MODE'
