import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SubHeadingStrip from '@/components/common/SubHeadingStrip/Index'
import GameList from '@/components/GameList/Index'
import {
  STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_HOME,
  STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_GAMECENTERS,
  STAT_APPS_PAGE_TYPEFOR_JUNGAMES_FROM_HOME,
  STAT_APPS_PAGE_TYPEFOR_MUSTPLAYGAMES_FROM_GAMECENTER,
  STAT_APPS_PAGE_TYPEFOR_NEWANDTRENDING_FROM_GAMECENTER,
  STAT_APPS_PAGE_TYPEFOR_TRENDINGGAMES_FROM_HOME,
  STAT_APPS_PAGE_TYPEFOR_POPULARGAMES_FROM_HOME,
  STAT_APPS_PAGE_MORE_STRATEGY_GAMES_FROM_APPDETAIL,
  STAT_APPS_PAGE_SIMILAR_GAMES_FROM_APPDETAIL,
} from '@/tool/constant'

import style from './index.module.scss'

// 根据不同来源-游戏列表
const typeNameMap = {
  cloudGames: 'cloudGame',
  recommendedApps: 'recommendedGames',
  topApps: 'topGames',
  hotApps: 'popularGames',
}

// 根据不同来源-打点类型
const dotTypeMap = {
  cloudGames_home: STAT_APPS_PAGE_TYPEFOR_JUNGAMES_FROM_HOME,
  topApps_home: STAT_APPS_PAGE_TYPEFOR_TRENDINGGAMES_FROM_HOME,
  hotApps_home: STAT_APPS_PAGE_TYPEFOR_POPULARGAMES_FROM_HOME,
  recommendedApps_home: STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_HOME,
  recommendedApps_gameCenter: STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_GAMECENTERS,
  topApps_gameCenter: STAT_APPS_PAGE_TYPEFOR_MUSTPLAYGAMES_FROM_GAMECENTER,
  hotApps_gameCenter: STAT_APPS_PAGE_TYPEFOR_NEWANDTRENDING_FROM_GAMECENTER,
  // app detail
  moreStrategyGames_appDetail: STAT_APPS_PAGE_MORE_STRATEGY_GAMES_FROM_APPDETAIL,
  similarGames_appDetail: STAT_APPS_PAGE_SIMILAR_GAMES_FROM_APPDETAIL,
}

const AllApps = () => {
  const location = useLocation()
  const urlParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])
  const gameType = urlParams.get('type')
  const from = urlParams.get('from')
  const headerTitle = urlParams.get('title')
  const selectorFn = useCallback(
    (state) => {
      const from = urlParams.get('from')
      const gameType = urlParams.get('type')
      switch (from) {
        case 'appDetail':
          const pck = urlParams.get('apk')
          const detail = state.appdetail.map[pck]
          return gameType === 'moreStrategyGames'
            ? detail['similar_category_games']
            : detail['similar_apps']
        default:
          return state.app[gameType] || state.cloud[gameType] || []
      }
    },
    [urlParams]
  )
  // 根据游戏类型获取游戏（云游戏、安卓游戏...）
  const allTypeGames = useSelector(selectorFn)

  const gameTitle = headerTitle || typeNameMap[gameType]

  const bannerLocation = dotTypeMap[`${gameType}_${from}`]

  // if (!allTypeGames.length)
  // return <CommonHelpTip textValue="noFoundBySearch" imgSrc="noResults" localSvg={true} />
  return (
    <div className={style.container}>
      <GameList
        gameItemProps={{
          imgPlaceholderClass: 'ratio-1-1',
          showPlayBtn: true,
          showCloudBtn: true,
          showInstalledState: true,
          showCsIcon: true,
          bannerLocation,
        }}
        list={allTypeGames}
        contentInnerClass={['breakScreen', 'marginBottom30']}
        columns={6}
        customTitleRender={() => {
          return (
            <SubHeadingStrip
              titleId={gameTitle}
              count={allTypeGames.length}
              className="breakScreen"
            />
          )
        }}
      />
    </div>
  )
}

export default React.memo(AllApps)
