/**
 * 轮播组件使用
 */

import React, { useCallback, useMemo } from 'react'
import CarouselLayout from '@/components/Carousel/CarouselLayout'
import GameListItem from '../common/GameListItem/GameListItem'
import { createGroup } from '@/tool'
const GameCarousel = ({
  customTitleRender,
  title,
  titleId,
  titleClass,
  className,
  linkTo,
  pagination,
  onRefresh,
  list,
  linkToText = 'viewAll',
  // slider组件props
  slideConfig = {},
  carouselKeyId,
  slidesPerView,
  slidesPerGroup,
  // GameListItem组件props
  showCloudBtn,
  showPlayBtn,
  showPcBtn,
  showDeleteBtn,
  showTypeIcon,
  onItemClick = () => {},
  showInstalledState,
  showLikeIncon,
  showCsIcon = true,
  imgPlaceholderClass,
  imageUrlGet,
  bannerLocation,
  bootGameOnClick,
  // Image组件props
  lazyRootMargin = '0px',
  onImageLoad,
  onImageError,
  imageDisplayStrategy,

  groupNumber = 0,
  renderItem = null,
  isGroup = true,
}) => {
  const itemProps = useMemo(() => {
    return {
      showCsIcon,
      bootGameOnClick,
      imgPlaceholderClass,
      bannerLocation,
      showInstalledState,
      onImageError,
      onImageLoad,
      imageDisplayStrategy,
      lazyRootMargin,
      showTypeIcon,
      showLikeIncon,
      showPcBtn,
      showCloudBtn,
      showPlayBtn,
      showDeleteBtn,
      onItemClick,
      imageUrlGet,
    }
  }, [
    showCsIcon,
    bootGameOnClick,
    imgPlaceholderClass,
    bannerLocation,
    showInstalledState,
    onImageError,
    onImageLoad,
    imageDisplayStrategy,
    lazyRootMargin,
    showTypeIcon,
    showLikeIncon,
    showPcBtn,
    showCloudBtn,
    showPlayBtn,
    showDeleteBtn,
    onItemClick,
    imageUrlGet,
  ])

  const sliderList = useMemo(() => {
    if (isGroup) {
      return createGroup(groupNumber || 1, list)
    }
    return list
  }, [list, groupNumber, isGroup])

  // 数据是否大于一页
  const upOnePage = useMemo(() => {
    if (isGroup) {
      if (list.length > groupNumber) {
        return true
      }
      return false
    }
    return list.length > slidesPerView
  }, [list.length, groupNumber, isGroup, slidesPerView])
  const renderChildJsx = useCallback(
    (slide, index) => {
      if (renderItem) {
        return renderItem({ slide, itemProps, index })
      }
      const renderSlides = isGroup ? slide : [slide]
      return (
        <>
          {renderSlides.map((item) => {
            return (
              <GameListItem
                key={item.package_name}
                {...itemProps}
                onClick={itemProps.onItemClick}
                selfLazyLoad={false}
                nameClass={`font18 bold`}
                categoryClass="font16 opaqueWhiteText"
                game={item}
              />
            )
          })}
        </>
      )
    },
    [renderItem, itemProps, isGroup]
  )
  if (sliderList.length === 0) {
    return null
  }
  return (
    <CarouselLayout
      carouselKeyId={carouselKeyId}
      list={sliderList}
      className={['gameCarousel', 'gameList', className]}
      title={title}
      titleId={titleId}
      titleClass={titleClass}
      linkTo={linkTo}
      pagination={pagination}
      onRefresh={onRefresh}
      linkToText={linkToText}
      slideConfig={slideConfig}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      customTitleRender={customTitleRender}
      upOnePage={upOnePage}
    >
      {renderChildJsx}
    </CarouselLayout>
  )
}

export default GameCarousel
