import produce from 'immer'
import * as types from './constant'

const INIT_STATE = {
  localPerference: {},
  // 已安装app
  installedApps: [],
  // gameCenter轮播图
  rotationBanners: [],
  // 推荐
  recommendedApps: [],
  // 热门
  hotApps: [],
  // top
  topApps: [],
  // 搜索结果
  searchResults: [],
  // 侧边栏
  sidepanel: {
    apps: [],
    tallBanners: [],
  },
  // 专题
  topics: [],
  topicAppsMap: {
    isFetchSuccess: true,
  },
  // 特色app
  feature: {
    game: null,
    app: null,
    list: [],
  },
  csApps: [],
}

export default produce((draft, action) => {
  const { type, payload } = action
  switch (type) {
    // 已安装app
    case types.SET_INSTALLED_APPS: {
      draft.installedApps = payload
      break
    }
    // rotation大图
    case types.FETCH_ROTATION_APPS: {
      draft.rotationBanners = payload
      break
    }
    // appcenter/game_center_home--gameCenter数据request
    case types.FETCH_GAME_CENTER_HOME_APPS: {
      const { recommendedApps, hotApps, topApps, topics } = payload
      draft.recommendedApps = recommendedApps
      draft.hotApps = hotApps
      draft.topApps = topApps
      draft.topics = topics
      break
    }
    case types.SHUFFLE_RECOMMENDED_APPS: {
      draft.recommendedApps = payload
      break
    }
    // 搜索App数据
    case types.FETCH_SEARCH_RESULTS: {
      draft.searchResults = payload
      break
    }
    // 设置特色App数据
    case types.FETCH_FEATURED_APPS: {
      const [game, app] = payload
      draft.feature.game = game
      draft.feature.app = app
      draft.feature.list = payload
      break
    }
    // 设置专题App数据
    case types.FETCH_TOPIC_APPS: {
      const { topic_id, apps, isFetchSuccess } = payload
      draft.topicAppsMap[topic_id] = apps
      draft.topicAppsMap.isFetchSuccess = isFetchSuccess
      break
    }
    // 设置专题App数据
    case types.SET_TOPIC_APPS: {
      const { topicId, apps } = payload
      draft.topicAppsMap[topicId] = apps
      break
    }
    // 设置sidepanel数据
    case types.FETCH_SIDEPANEL_APPS: {
      const { apps, tallBanners } = payload
      draft.sidepanel.apps = apps
      draft.sidepanel.tallBanners = tallBanners
      break
    }
    // CS app
    case types.SET_CS_APPS: {
      draft.csApps = payload
      break
    }
    // local perference
    case types.SET_LOCAL_PERFERENCE: {
      draft.localPerference = payload
      break
    }
    default: {
    }
  }
}, INIT_STATE)
