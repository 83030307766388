import * as types from './constant'

// 是否为Premium账户
export const setIsPremiumAccount = () => {
  const response = window.GmApi.gmIsPremiumUser()
  return (dispatch) => {
    const result = response.toLowerCase() === 'true'
    dispatch({
      type: types.SET_PREMIUM_ACCOUNT,
      payload: { premiumAccount: result },
    })
  }
}

// 保存账户信息
export const setUserInfo = ({ email, token, userid, xtoken }) => {
  return {
    type: types.SET_USER_INFO,
    payload: { email, token, userid, xtoken },
  }
}

// 设置主题
export const setTheme = (theme) => {
  return {
    type: types.SET_THEME,
    payload: theme,
  }
}

// 设置主题
export const setLanguage = (lang) => {
  return {
    type: types.SET_APP_LANGUAGE,
    payload: lang,
  }
}

// 设置client配置
export const setClientUserConfig = (config) => {
  return {
    type: types.CLIENT_USER_CONFIG,
    payload: config,
  }
}
