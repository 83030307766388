import axios from 'axios'
import store from '@/store'
import { setAppOfflinePage } from '@/reducers/system/actions'

/**
 * 校验请求的成功与否，返回统一格式
 * @param {AxiosResponse} response axios回复对象
 * @returns [Object,False]
 */
const defaultValidate = (response) => {
  if (response.data && response.data.success) {
    return response.data.result
  }
  return false
}

// 取消请求map
const cancelTokenMap = new Map()

const CancelToken = axios.CancelToken
/**
 * 导出同意axios请求方法
 */
const fetch = (config, validate = defaultValidate) => {
  const source = CancelToken.source()
  return new Promise((resolve, reject) => {
    // 重复请求处理
    const seedUrl = `${config.url}_seed`
    if (cancelTokenMap.has(seedUrl)) {
      const cancel = cancelTokenMap.get(seedUrl)
      cancel('Cancel duplicate API')
      cancelTokenMap.delete(seedUrl)
    }
    cancelTokenMap.set(seedUrl, source.cancel)
    try {
      axios({ ...config, cancelToken: source.token })
        .then(
          (response) => {
            if (cancelTokenMap.has(seedUrl)) {
              cancelTokenMap.delete(seedUrl)
            }
            if (config.noValidate) {
              resolve(response.data)
            } else {
              const _validateResponse = validate(response)
              if (_validateResponse) {
                resolve(_validateResponse)
              } else {
                reject(response)
              }
            }
          },
          (error) => {
            // console.log(error)
            if (error.message === 'Network Error' && config.showDisconnect) {
              store.dispatch(setAppOfflinePage(true))
            }
            // cancel掉的请求不予处理
            if (axios.isCancel(error)) {
              return
            }
            if (cancelTokenMap.has(seedUrl)) {
              cancelTokenMap.delete(seedUrl)
            }
            reject(error)
          }
        )
        .finally((res) => {
          // if (cancelTokenMap.has(seedUrl) && axios.isCancel(res)) {
          //   cancelTokenMap.delete(seedUrl)
          //   console.log(seedUrl, 'cancel-----------------', cancelTokenMap.size)
          // }
          // cancelTokenMap.delete(seedUrl)
          // console.log(seedUrl, 'cancel-----------------', cancelTokenMap.size)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export default fetch
