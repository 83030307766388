import { useState, useEffect, useCallback } from 'react'
import { on, off, qwebApi } from '@/apis/qwebApi'

const Hook = () => {
  const [cloudMode, setCloudMode] = useState({})
  useEffect(() => {
    qwebApi({ event: 'getCloudMode', callbackEvent: 'updateCloudMode' })
  }, [])
  // 更新回调
  const onUpdateCloudMode = useCallback((jsonObject) => {
    try {
      if (jsonObject) {
        setCloudMode(jsonObject)
      }
    } catch (error) {
      setCloudMode({})
    }
  }, [])
  // 监听云模式变化
  useEffect(() => {
    on('updateCloudMode', onUpdateCloudMode)
    return () => {
      off('updateCloudMode', onUpdateCloudMode)
    }
  }, [onUpdateCloudMode])
  return cloudMode
}

export default Hook
