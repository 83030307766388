import React, { useRef, forwardRef, useState, useMemo } from 'react'
import classnames from 'classnames'
import ResponsiveLayout from '@/components/common/reponsiveLayout/Index'
import Scrollload from '@/components/scrollload/Scrollload'
const TitleBarLayout = (
  {
    className,
    // reponsive
    wrapInOneLine,
    moveType,
    keyid,
    numberOfItems,
    gutter,
    contentClass,
    children,
    list,
    wrap,
    lineNumber,
    isPuzzle,
    customTitleRender,
    root,
    rootMargin,
    scrollContainer,
    listAppend,
    prefixAppend,
    // 动画相关
    mainAniStyle,
    inStyle,
    outStyle,
    inClass,
    outClass,
  },
  ref
) => {
  const [isMount, setIsMount] = useState(false)
  const reponsiveRef = useRef()
  // 标题栏
  const titleBarHeaderJsx = useMemo(() => {
    if (!isMount) {
      return null
    }
    let titleJSX = null
    if (customTitleRender) {
      titleJSX =
        typeof customTitleRender === 'function'
          ? customTitleRender(reponsiveRef)
          : customTitleRender
    }
    return titleJSX
  }, [customTitleRender, isMount])
  const scrollContainerValue = scrollContainer || (() => reponsiveRef.current?.dom)
  return (
    <div className={classnames(['titleBarLayout', className])} ref={ref}>
      {titleBarHeaderJsx}
      <Scrollload
        list={list}
        scrollContainer={scrollContainerValue}
        numberOfItems={numberOfItems}
        render={(scrollList, pageSize) => {
          return (
            <ResponsiveLayout
              onMount={() => setTimeout(() => setIsMount(true))}
              ref={reponsiveRef}
              className={['titleBarLayoutContent', contentClass]}
              numberOfItems={pageSize}
              gutter={gutter}
              list={scrollList}
              wrap={wrap}
              wrapInOneLine={wrapInOneLine}
              keyid={keyid}
              moveType={moveType}
              isPuzzle={isPuzzle}
              lineNumber={lineNumber}
              root={root}
              rootMargin={rootMargin}
              mainAniStyle={mainAniStyle}
              inStyle={inStyle}
              outStyle={outStyle}
              inClass={inClass}
              outClass={outClass}
              listAppend={listAppend}
              prefixAppend={prefixAppend}
            >
              {(item, index, options) => children(item, index, { ...options, ref: reponsiveRef })}
            </ResponsiveLayout>
          )
        }}
      />
    </div>
  )
}

export default forwardRef(TitleBarLayout)
