import { useEffect, useLayoutEffect, useRef } from 'react'

import { containerValueFinder } from '@/tool'

const memoryCache = {
  store: {},
  get(key) {
    return memoryCache.store[key]
  },
  set(key, value) {
    memoryCache.store[key] = value
    return Object.keys(memoryCache.store)
  },
}

const defaultOptions = {
  // 是否开启smooth模式
  smooth: false,
  // 存储器
  store: memoryCache,
  // 缓存key
  key: 'cacheKey',
  // 最大尝试，次数
  maxHit: 20,
}

const Hook = (ref, options) => {
  const optionsRef = useRef({
    ...defaultOptions,
    ...options,
  })

  // 卸载前记录位置
  useLayoutEffect(() => {
    const scrollContainer = containerValueFinder(ref)
    const { store, key } = optionsRef.current
    return () => {
      const { scrollTop, scrollLeft } = scrollContainer
      store.set(key, {
        scrollTop,
        scrollLeft,
      })
    }
  }, [ref])

  // 渲染后回复位置
  useEffect(() => {
    const scrollContainer = containerValueFinder(ref)
    const { smooth, store, key, maxHit } = optionsRef.current
    let isValid = true
    let cancelRequestFrame = null
    let hitCount = 0
    let positionInfo = {
      top: 0,
      left: 0,
    }
    const cachePosition = store.get(key)
    if (cachePosition) {
      positionInfo = {
        left: cachePosition.scrollLeft,
        top: cachePosition.scrollTop,
      }
    }
    const runFrame = () => {
      if (isValid) {
        if (
          scrollContainer.scrollTop < positionInfo.top ||
          scrollContainer.scrollLeft < positionInfo.left
        ) {
          scrollContainer.scrollTo({
            ...positionInfo,
            behavior: smooth ? 'smooth' : 'auto',
          })
          if (++hitCount <= maxHit) {
            // console.log(hitCount)
            cancelRequestFrame = requestAnimationFrame(runFrame)
          }
        }
      }
    }
    cancelRequestFrame = requestAnimationFrame(runFrame)
    return () => {
      isValid = false
      cancelAnimationFrame(cancelRequestFrame)
    }
  }, [ref])
}

export default Hook
