import { qwebApi } from '@/apis/qwebApi'
import Utils from '@/Utils'
import * as BannerLocation from '@/tool/constant'

// 点类型
export const STATS_POINT = {
  // cs
  BSX_CS_ON_SAVE_MOD: 'save_button_clicked',
  BSX_CS_ON_CREATE_MOD: 'create_mod_clicked',
  BSX_CS_ON_PLAY_MOD: 'play_button_clicked',
  BSX_CS_ON_SHARE_MOD: 'share_button_clicked',
  BSX_CS_UPLOAD_EFFECT: 'upload_effect',
  BSX_CS_BUTTON_CLICKED: 'creator_hub_icon_clicked',
  // bsx
  BSX_JUMP_TO_DETAIL_PAGE: 'App_JumpToDetailPage',
  BSX_SEARCH_IN_GP_CLICKED: 'SearchInGP_Clicked',
}

// 打点table
export const TABLE_TYPE = {
  // cs
  IMPRESSIONS: 'CreatorStudioImpressions',
  MOD_EVENTS: 'CreatorStudioModEvents',
  UPLOAD_EFFECTS: 'CreatorStudioUploadEffects',
  ACTIVE_USERS: 'CreatorStudioActiveUsers',
  // bsx
  LAUNCHER_APP_CLICK: 'launcher_app_click_stats',
  LAUNCHER_APP_CLICK_V2: 'launcher_app_click_statsV2',
}

// view all key word
const VIEW_ALL = 'view all'

// 根据打点信息获取当前页面
export const getPathNameLocationMap = (statInfo) => {
  const pageMap = {
    search_results_page: 'search',
    app_page: 'app_detail',
    home: 'home',
    game_center: 'game_center',
    apps: 'apps',
  }
  let _path = statInfo.banner_location
  if (statInfo.arg1 === VIEW_ALL) {
    _path = 'apps'
  }
  return pageMap[_path]
}

// 获取打点信息
export const getLocationMapStatInfo = (bannerLocation) => {
  const statInfo = {
    banner_location: '',
    section: '',
  }
  switch (bannerLocation) {
    // 查看所有apps
    case BannerLocation.STAT_SEARCH_PAGE_RESULT:
      statInfo.banner_location = 'search_results_page'
      statInfo.section = 'search_results'
      break
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_GAMECENTERS:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'recommended_games'
      statInfo.arg1 = VIEW_ALL
      break

    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_TRENDINGGAMES_FROM_HOME:
      statInfo.banner_location = 'home'
      statInfo.section = 'trending_games'
      statInfo.arg1 = VIEW_ALL
      break
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_POPULARGAMES_FROM_HOME:
      statInfo.banner_location = 'home'
      statInfo.section = 'popular_games'
      statInfo.arg1 = VIEW_ALL
      break
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_HOME:
      statInfo.banner_location = 'home'
      statInfo.section = 'recommended_games'
      statInfo.arg1 = VIEW_ALL
      break
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_JUNGAMES_FROM_HOME:
      statInfo.banner_location = 'home'
      statInfo.section = 'jun_games'
      statInfo.arg1 = VIEW_ALL
      break
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_MUSTPLAYGAMES_FROM_GAMECENTER:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'must_play_games'
      statInfo.arg1 = VIEW_ALL
      break
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_NEWANDTRENDING_FROM_GAMECENTER:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'new_and_trending_games'
      statInfo.arg1 = VIEW_ALL
      break
    case BannerLocation.STAT_APPS_PAGE_TYPEFOR_TOPIC_FROM_ANDROIDGAMES:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'collection_games'
      statInfo.arg1 = Utils.getParamByName('topicId')
      break
    case BannerLocation.STAT_APPS_PAGE_MORE_STRATEGY_GAMES_FROM_APPDETAIL:
      statInfo.banner_location = 'app_page'
      statInfo.section = 'strategy_games'
      statInfo.arg1 = VIEW_ALL
      break
    case BannerLocation.STAT_APPS_PAGE_SIMILAR_GAMES_FROM_APPDETAIL:
      statInfo.banner_location = 'app_page'
      statInfo.section = 'similar_games'
      statInfo.arg1 = VIEW_ALL
      break
    // gameCenter页面
    case BannerLocation.STAT_GAMECENTER_PAGE_RECOMMENDED:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'recommended_games'
      break
    case BannerLocation.STAT_GAMECENTER_PAGE_FEATURE:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'feature_games'
      break
    case BannerLocation.STAT_GAMECENTER_PAGE_MUSTPLAYGAMES:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'must_play_games'
      break
    case BannerLocation.STAT_GAMECENTER_PAGE_NEWANDTRENDING:
      statInfo.banner_location = 'game_center'
      statInfo.section = 'new_and_trending_games'
      break
    // home页面
    case BannerLocation.STAT_HOME_PAGE_BANNER:
      statInfo.banner_location = 'home'
      statInfo.section = 'suggested_apps'
      break
    case BannerLocation.STAT_HOME_PAGE_JUNGAMES:
      statInfo.banner_location = 'home'
      statInfo.section = 'jun_games'
      break
    case BannerLocation.STAT_HOME_PAGE_TRENDINGGAMES:
      statInfo.banner_location = 'home'
      statInfo.section = 'trending_games'
      break
    case BannerLocation.STAT_HOME_PAGE_POPULARGAMES:
      statInfo.banner_location = 'home'
      statInfo.section = 'popular_games'
      break
    // appDetail页面
    case BannerLocation.STAT_APP_DETAIL_PAGE_BANNER:
      statInfo.banner_location = 'app_page'
      statInfo.section = 'banner'
      break
    case BannerLocation.STAT_APP_DETAIL_PAGE_MORE_STRATEGY_GAMES:
      statInfo.banner_location = 'app_page'
      statInfo.section = 'strategy_games'
      break
    case BannerLocation.STAT_APP_DETAIL_PAGE_SIMILAR_GAMES:
      statInfo.banner_location = 'app_page'
      statInfo.section = 'similar_games'
      break
    default:
      break
  }
  return statInfo
}

// cs专用打点
const stats = async (tableType, eventName, values = {}) => {
  const _values = {}
  for (let key in values) {
    const value = values[key]
    if (typeof value === 'function') {
      try {
        _values[key] = await value(key)
      } catch (error) {}
    } else {
      _values[key] = value
    }
  }
  const statData = { ..._values }
  if (eventName) {
    statData.event_name = eventName
  }
  qwebApi({
    event: 'postStats',
    data: {
      table: tableType,
      data: statData,
    },
  })
}

Object.defineProperty(stats, 'TABLE_TYPE', {
  value: TABLE_TYPE,
  writable: false,
})

Object.defineProperty(stats, 'STATS_POINT', {
  value: STATS_POINT,
  writable: false,
})

export default stats
