import React from 'react'

import style from './index.module.scss'
const CollectionItem = ({ topic, styleObj, onClick }) => {
  return (
    <span className={style.item} style={styleObj} onClick={() => onClick(topic)}>
      {topic.topic_name}
    </span>
  )
}

export default React.memo(CollectionItem)
