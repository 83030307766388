/**
 * 判断url是否为YouTube链接
 * @param {string} url url链接
 * @returns 是否为youtube链接
 */
export const isYoutubeUrl = (url) => {
  if (url && typeof url === 'string') {
    return url.startsWith('https://www.youtube.com')
  }
  return false
}

/**
 * 转换为可被嵌入的youtube链接
 * @param {string} url 带转换链接
 * @returns 可被嵌入的youtube链接
 */
export const transformToEmbedUrl = (url) => {
  if (isYoutubeUrl(url)) {
    return url.replace(/\/watch\?v=/, '/embed/')
  }
  throw new Error('Invalid youtube url.')
}
