import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/common/Button/Button'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import { emit } from '@/apis/qwebApi'
import Utils from '@/Utils'
import { sendCodeToXServicerByGet } from '@/apis/account'
import style from './index.module.scss'
import { compareVersionHigh } from '@/tool/version'
const defaultState = {
  loginType: 'google',
  locale: 'en',
  uuid: '',
  version: '',
}
function getUplStateParams() {
  const stateJSON = Utils.getParamByName('state')
  try {
    return { ...defaultState, ...JSON.parse(stateJSON) }
  } catch (error) {
    return defaultState
  }
}
const Account = () => {
  const [status, setStatus] = useState(0)
  const { loginType, locale, uuid, version } = getUplStateParams()
  const code = Utils.getParamByName('code')
  useEffect(() => {
    const timer = setTimeout(() => {
      emit('updateLanguage', { language: locale, defaultLanguage: 'en' })
    }, 100)
    return () => clearTimeout(timer)
  }, [locale])

  // 发送登录信息给服务端
  const sendCodeToServicerHandler = useCallback(async ({ code, uuid, loginType, version }) => {
    setStatus(0)
    try {
      const isVersionNew = version && compareVersionHigh(version, '0.13.2.5', 1)
      await sendCodeToXServicerByGet({ code, uuid, loginType }, isVersionNew)
      setStatus(1)
    } catch (error) {
      setStatus(-1)
    }
  }, [])

  useEffect(() => {
    sendCodeToServicerHandler({ code, uuid, loginType, version })
  }, [])

  const onEvokeClient = useCallback(() => {
    window.location.href = `BlueStacksX:${JSON.stringify({ event: 'accountEvoke', data: null })}`
  }, [])

  const footerJsx = useMemo(() => {
    if (status === -1) {
      return (
        <p className={style.loginExpired}>
          <FormattedMessage id="loginExpired" />
        </p>
      )
    }
    if (status === 1) {
      return (
        <Button
          onClick={onEvokeClient}
          key="evokeButton"
          type="primaryChange"
          titleId="accountContinue"
          className={style.button}
        />
      )
    }
    return (
      <Button
        className={style.button}
        key="disabledButton"
        disabled={true}
        isLoading={true}
        type="primaryChange"
      />
    )
  }, [status, onEvokeClient])
  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className={style.body}>
          <div className={style.haeder}>
            <img src={require('@/images/logo_fill.svg').default} alt="game icon" />
            <SvgIcon size={2} icon="flow-next" className={style.icon} />
            <img src={require(`@/images/${loginType}_circle.svg`)} alt="game icon" />
          </div>
          <div className={style.title}>
            <FormattedMessage id="accountStatus" />
          </div>
        </div>
        <div className={style.footer}>{footerJsx}</div>
      </div>
    </div>
  )
}

export default Account
