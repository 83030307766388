import produce from 'immer'
import * as types from './constant'

const INIT_STATE = {
  cloudGames: [],
  isFetchSuccess: true,
}
export default produce((state, { type, payload }) => {
  switch (type) {
    case types.FETCH_CLOUDGAMES_APPS:
      state.cloudGames = payload.cloudGames
      state.isFetchSuccess = payload.isFetchSuccess
      break
    default: {
    }
  }
}, INIT_STATE)
