import React, { useEffect } from 'react'
// import { findDOMNode } from 'react-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Switch, Route, useHistory } from 'react-router-dom'
import Utils from '@/Utils'
import { qwebApi } from '@/apis/qwebApi'
import './index.css'
const ANIMATION_MAP = {
  PUSH: 'translateX',
  POP: 'reTranslateX',
  REPLACE: 'reTranslateX',
}
// const cachePosition = (window.cachePosition = {})
function Animate({ children }) {
  const history = useHistory()
  // 根据动作自行判断前进和后退(路由级别的位置记录)
  /*使用React.cloneElement API对props中的classNames这一props进行修改 */
  useEffect(() => {
    // const routerRootContainer = findDOMNode(routerRef.current)
    history.listen(() => {
      qwebApi({ event: 'changeBannerBackgroundImage' })
      // const curretnPathname = location.pathname
      // cachePosition[curretnPathname] = routerRootContainer.scrollTop
      // if (action === 'POP') {
      //   routerRootContainer.scrollTop = cachePosition[curretnPathname] || 0
      // } else {
      //   routerRootContainer.scrollTop = 0
      // }
      // console.log('cachePosition--------------------->',cachePosition)
    })
    // return () => unlisten()
  }, [])
  return (
    <Route
      render={({ location, history }) => (
        <TransitionGroup
          className="contentInner"
          childFactory={(child) => {
            const animation = Utils.getParamByName('animation')
            let classNames = ANIMATION_MAP[history.action]
            if (animation && history.action === 'PUSH') {
              classNames = animation
            }
            return React.cloneElement(child, {
              classNames,
            })
          }}
        >
          <CSSTransition timeout={200} key={location.pathname}>
            <Switch location={location}>{children}</Switch>
          </CSSTransition>
        </TransitionGroup>
      )}
    ></Route>
  )
}

export default Animate
