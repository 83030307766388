/**
 * 只有home banner使用，待改造
 */

import React, { useRef } from 'react'
import TitleBarLayout from '../common/layout/TitleBarLayout'
import GameListItem from '../common/GameListItem/GameListItem'
// import CommonBar from './CommonBar'
const GameList = ({
  // TitleBar组件props（customTitleRender为null有效）
  title,
  titleClass,
  pagination,
  showFreshIcon,
  carouselKeyId = 'common',
  linkTo,
  activeType,
  // 是否自定义Title
  customTitleRender,
  list,
  // 栏目class
  className,
  contentClass,
  // TitleBarLayout组件props
  // 是否换行显示
  wrap,
  wrapInOneLine,
  moveType,
  listAppend,
  prefixAppend,
  // 一行显示数据个数计算函数
  numberOfItems = 4,
  gutter,
  // 显示几行（在wrap为false时有效）
  lineNumber,
  // GameListItem组件props
  showCloudBtn,
  showPlayBtn,
  showPcBtn,
  showDeleteBtn,
  showTypeIcon,
  onRemoveClick,
  onItemClick,
  onRootClick,
  onHoverEnter,
  showInstalledState,
  showLikeIncon,
  imgPlaceholderClass,
  imageUrlGet,
  itemClassName = 'buttonVertical',
  hasHoverAction,
  hoverImageUrlGet,
  // 模块位置（用于记录打点）
  bannerLocation,
  // Image组件props
  lazyRootMargin,
  lazyRoot = () => document.querySelector('.contentInner'),
  onImageLoad,
  onImageError,
  imageDisplayStrategy,
  scrollContainer,
  // layout
  layoutRootMargin,
  layoutRoot,
  // 动画相关
  mainAniStyle,
  inStyle,
  outStyle,
  inClass,
  outClass,
  // 事件注册
  // onPrev,
  // onNext,
  // onRefresh,
  bootGameOnClick,
  showCsIcon = true,
}) => {
  const titleBarLayoutRef = useRef(null)
  // const commonBarRef = useRef(null)

  const layoutRootFinally = wrapInOneLine ? titleBarLayoutRef : layoutRoot || lazyRoot
  const lazyRootFinally = wrapInOneLine ? titleBarLayoutRef : lazyRoot || layoutRoot
  return (
    <TitleBarLayout
      ref={titleBarLayoutRef}
      className={[`${carouselKeyId}Container`, className]}
      root={layoutRootFinally}
      rootMargin={layoutRootMargin}
      contentClass={['gameList', contentClass]}
      numberOfItems={numberOfItems}
      gutter={gutter}
      list={list}
      wrap={wrap}
      wrapInOneLine={wrapInOneLine}
      moveType={moveType}
      lineNumber={lineNumber}
      isPuzzle={showFreshIcon}
      keyid={(item, index) => `${item.package_name}-${index}`}
      mainAniStyle={mainAniStyle}
      inStyle={inStyle}
      outStyle={outStyle}
      inClass={inClass}
      outClass={outClass}
      scrollContainer={scrollContainer}
      listAppend={listAppend}
      prefixAppend={prefixAppend}
      customTitleRender={
        customTitleRender
        // ((contentRef) => {
        //   return (
        //     <CommonBar
        //       ref={commonBarRef}
        //       title={title}
        //       list={list}
        //       titleClass={titleClass}
        //       layoutRef={contentRef}
        //       showFreshIcon={showFreshIcon}
        //       pagination={pagination}
        //       onPrev={onPrev}
        //       onNext={onNext}
        //       onRefresh={onRefresh}
        //       linkTo={linkTo}
        //       activeType={activeType}
        //     />
        //   )
        // })
      }
    >
      {(item, index, { ref }) => {
        return (
          <GameListItem
            imageUrlGet={imageUrlGet}
            onClick={onItemClick}
            onRootClick={onRootClick}
            onRemoveClick={onRemoveClick}
            showDeleteBtn={showDeleteBtn}
            showPlayBtn={showPlayBtn}
            showCloudBtn={showCloudBtn}
            showPcBtn={showPcBtn}
            showLikeIncon={showLikeIncon}
            showTypeIcon={showTypeIcon}
            lazyRoot={lazyRootFinally}
            showCsIcon={showCsIcon}
            lazyRootMargin={lazyRootMargin}
            imageDisplayStrategy={imageDisplayStrategy}
            onImageLoad={onImageLoad}
            onImageError={onImageError}
            showInstalledState={showInstalledState}
            bannerLocation={bannerLocation}
            nameClass={`font18 bold`}
            className={itemClassName}
            categoryClass="font16 opaqueWhiteText"
            imgPlaceholderClass={imgPlaceholderClass}
            // isVideo={item.video_url}
            game={item}
            bootGameOnClick={bootGameOnClick}
            hasHoverAction={hasHoverAction}
            hoverImageUrlGet={hoverImageUrlGet}
            onHoverEnter={onHoverEnter && (() => onHoverEnter(item, ref, index))}
          />
        )
      }}
    </TitleBarLayout>
  )
}

export default React.memo(GameList)
