import Utils from '../Utils'
import store from '@/store'
const params = Utils.getParams()

// 获取查询通用参数
export const getFormDataBySearch = (append = {}) => {
  const formData = new FormData()
  const queryParams = new URLSearchParams(params)
  for (let [key, value] of queryParams.entries()) {
    formData.set([key], value)
  }
  for (let key in append) {
    formData.set(key, append[key])
  }
  return formData
}

// 通用log工具
export const log = (...args) => {
  console.log(...args)
}
// 通用log工具
export const errorLog = (...args) => {
  console.error(...args)
}

// 判断是否为子节点
export const isChildOf = (child, parent) => {
  if (child && parent) {
    let parentNode = child.parentNode
    while (parentNode) {
      if (parent === parentNode) {
        return true
      }
      parentNode = parentNode.parentNode
    }
  }
  return false
}

// 随机获取索引{isRandomOrder:判断是否是随机索引}
export const getRandomIndexs = (length, size = length, used = [], isRandomOrder) => {
  let indexSet = new Set()
  let usedIndexs = new Set(used)
  const miniSize = Math.min(size, length)
  let lastIndex = used[used.length - 1]
  if (lastIndex === undefined) {
    lastIndex = -1
  }
  while (indexSet.size < miniSize) {
    // 项目已全部使用完，则重新开始标记
    if (usedIndexs.size === length) {
      usedIndexs = new Set(indexSet)
    }
    let randomIndex
    if (!isRandomOrder) {
      randomIndex = (lastIndex + 1) % length
      lastIndex = randomIndex
    } else {
      randomIndex = Math.floor(length * Math.random())
      while (usedIndexs.has(randomIndex)) {
        randomIndex = Math.floor(length * Math.random())
      }
    }
    indexSet.add(randomIndex)
    usedIndexs.add(randomIndex)
  }
  return [[...indexSet], [...usedIndexs]]
}

// 容器获取函数
export const containerValueFinder = (domFinder) => {
  if (typeof domFinder === 'string') {
    return document.querySelector(domFinder)
  }
  if (domFinder instanceof Element) {
    return domFinder
  }
  if (typeof domFinder === 'function') {
    return domFinder()
  }
  if (typeof domFinder === 'object' && domFinder.current) {
    return domFinder.current
  }
  return document.body
}

export const arrayShuffle = (array) => {
  for (
    let j, x, i = array.length;
    i;
    j = parseInt(Math.random() * i), x = array[--i], array[i] = array[j], array[j] = x
  );
  return array
}

export const createGroup = (num, arr) => {
  // 一维数组转换为二维数组
  const transArr = [] // 声明数组
  arr.forEach((item, index) => {
    const page = Math.floor(index / num) // 计算该元素为第几个素组内
    if (!transArr[page]) {
      // 判断是否存在
      transArr[page] = []
    }
    transArr[page].push(item)
  })
  return transArr
}

// 根据配置获取app按钮文案,location表示在哪个模块应用，默认common通用,oneButtonConfig button模式默认为多按钮模式
export const getButtonText = (
  intl,
  {
    // 是否安装
    isInstalled = false,
    app,
    // 优先平台
    platforms,
    // 是否为云模式
    isCloudMode = false,
    // 模块位置
    location = 'common',
    // one button模式（3：多按钮；0：单按钮）
    oneButtonConfig = 3,
  }
) => {
  const {
    isInstallCDN = false,
    isOpenInBrowser = false,
    button_text,
    package_name,
    game_play_preference = ['local'],
  } = app
  // 是否为one button模式
  const isAutoConfig = oneButtonConfig === 0
  // 优先判断是否是google store app
  const isGoogleIcon = package_name === 'com.android.vending'
  if (isGoogleIcon) {
    return {
      actionText: intl.formatMessage({ id: 'openGooglePlay' }),
      actionCode: 'openGooglePlay',
      gameType: 'simulator',
    }
  }
  // 是否是体验预注册app
  if (isOpenInBrowser) {
    return { actionText: button_text, actionCode: button_text }
  }

  const isAppDetailAccessable =
    app.isAppDetailAccessable && store.getState().system.countryCode !== 'JP'
  // 判断优先启动平台
  const firstPlay = platforms || game_play_preference[0]
  if (firstPlay === 'cloud') {
    return {
      actionText: intl.formatMessage({
        id:
          isAutoConfig && isAppDetailAccessable
            ? location === 'appDetailPage'
              ? 'playInstantlyInBrowser'
              : 'viewDetails'
            : // 'playInstantly'
              'playOnCloud',
      }),
      actionCode: 'playOnCloud',
      gameType: 'cloud',
    }
  }
  if (firstPlay === 'local') {
    // 判断是否安装
    if (isInstalled) {
      return {
        actionText: intl.formatMessage({ id: 'playOnAppPlayer' }),
        actionCode: 'playOnAppPlayer',
        gameType: 'simulator',
      }
    } else {
      const isShowViewDetails = location === 'common' && isAutoConfig
      if (isInstallCDN) {
        return {
          actionText: intl.formatMessage({
            id: isShowViewDetails && isAppDetailAccessable ? 'viewDetails' : 'installInAppPlayer',
          }),
          actionCode: 'installInAppPlayer',
          gameType: 'simulator',
        }
      } else {
        return {
          actionText: intl.formatMessage({
            id: isShowViewDetails && isAppDetailAccessable ? 'viewDetails' : 'installViaGooglePlay',
          }),
          actionCode: 'installViaGooglePlay',
          gameType: 'simulator',
        }
      }
    }
  }
  return {}
}

export const createPromise = () => {
  const promise = {}
  const r = new Promise((resolve, reject) => {
    promise.resolve = resolve
    promise.reject = reject
  })
  promise.then = Promise.prototype.then.bind(r)
  promise.catch = Promise.prototype.catch.bind(r)
  return promise
}
