import React from 'react'
import classnames from 'classnames'
import Heading from '../Heading/Heading'
import style from './index.module.scss'
const TitleBar = ({ title, titleId, titleClass = null, renderRight = null, renderLeft = null }) => {
  return (
    <div className={classnames([style.container, titleClass, 'sectionHeading', 'heading'])}>
      <div className={style.headerLeft}>
        {(title || titleId) && <Heading title={title} titleId={titleId} />}
        {renderLeft}
      </div>
      <div className={style.headerRight}>{renderRight}</div>
    </div>
  )
}

export default React.memo(TitleBar)
