import { compareVersionHigh } from '@/tool/version'

/**
 * 判断app是否支持打开详情页功能
 * @param {package_name,action,source} app
 * @returns boolean
 */
export function isAppDetailAccessable(app) {
  const isAppDetailVersion = compareVersionHigh(window.__CLIENT_CONFIG__.x_version, '0.14.3.5', 1)
  const isGoogleIcon = app.package_name === 'com.android.vending'
  const isBrowserAction = ['ApplicationBrowser', 'UserBrowser'].includes(app.action)
  const isSteamApp = app.source === 'steam'
  if (isGoogleIcon || isBrowserAction || isSteamApp || !isAppDetailVersion) {
    return false
  }
  return true
}

/**
 * 云游戏链接统一加上source=launcher参数
 * @param {string} url
 * @param {source} params
 * @returns string
 */
function cloudGameUrlAdapt(url, params = { source: 'launcher' }) {
  if (url) {
    const paramsString = new URLSearchParams(params)
    if (url.includes('?')) {
      return `${url}&${paramsString}`
    }
    return `${url}?${paramsString}`
  }
  return url
}

/**
 * 获取指定大小的图片链接(指定包名及大小)
 * @param {pkg,size} param0
 * @returns string
 */
export const getSpecialSizeIcon = ({ pkg, size = 300 }) => {
  return `${window.__HOST_CONFIG__.baseHost}/app/icon?pkg=${pkg}&use_cdn=true&w=${size}`
}

/**
 * 获取指定大小的图片链接(指定原始链接及大小)--topic app使用
 * @param {string} url
 * @param {number} size
 * @returns string
 */
export const getSpecialSizeIconByReplace = (url, size = 300) => {
  return url && url.replace(/^(.+)=w(\d+)$/, (all, match) => `${match}=w${size}`)
}

/**
 * 根据条件使用icon_url
 * @param {action,iconUrl,packageName} param0
 * @returns string
 */
export const getIconUrlByStrategy = ({ action, iconUrl, packageName }) => {
  return ['ApplicationBrowser', 'UserBrowser'].includes(action)
    ? iconUrl
    : getSpecialSizeIcon({ pkg: packageName })
}

/**
 * 获取game center适配格式(/appcenter/game_center_home)
 * @param {array<app>} apps
 * @returns array<app>
 */
const getGameCenterAppAdapt = (apps) => {
  return apps.map((app) => ({
    ...app,
    // client必须
    package_name: app.id,
    game_name: app.title,
    cloud_app_url: cloudGameUrlAdapt(app.cloud_app_url),
    cloud_supported: app.cloud_supported,
    icon_url: getIconUrlByStrategy({
      action: app.action,
      packageName: app.id,
      iconUrl: app.icon_url,
    }),
    // webs使用
    category: app.category,
    platform_name: app.platform_name,
    banner_url_v: app.game_tile_url,
    showCloudBtn: !!app.cloud_supported,
    showPlayBtn: true,
    showPcBtn: false,
    // 动作为浏览器打开（配置不可点击详情页操作）
    isBrowserAction: ['ApplicationBrowser', 'UserBrowser'].includes(app.action),
    // 个性化按钮文案(不显示availableNow)
    isOpenInBrowser: ['ApplicationBrowser', 'UserBrowser', 'OpenGooglePlayPage'].includes(
      app.action
    ),
    isInstallCDN: app.action === 'InstallCDN' && app.apk_url,
    isAppDetailAccessable: isAppDetailAccessable(app),
  }))
}

/**
 * 轮播数据适配->rotationBanners
 * @param {array<app>} apps
 * @returns array<app>
 */
export const rotationDataTransformer = (apps) => {
  const rotationBanners = apps
    .filter((app) => app.package_name && app.game_name)
    .map((app) => {
      return {
        ...app,
        // client必须
        package_name: app.package_name,
        game_name: app.game_name,
        cloud_app_url: cloudGameUrlAdapt(app.cloud_app_url),
        cloud_supported: app.cloud_supported,
        icon_url: getIconUrlByStrategy({
          action: app.action,
          packageName: app.package_name,
          iconUrl: app.icon_url,
        }),
        // webs使用
        platform_name: app.platform_name,
        category: app.category,
        showCloudBtn: !!app.cloud_supported,
        showPlayBtn: true,
        showPcBtn: false,
        // 动作为浏览器打开（配置不可点击详情页操作）
        isBrowserAction: ['ApplicationBrowser', 'UserBrowser'].includes(app.action),
        // 个性化按钮文案(不显示availableNow)
        isOpenInBrowser: ['ApplicationBrowser', 'UserBrowser', 'OpenGooglePlayPage'].includes(
          app.action
        ),
        isInstallCDN: app.action === 'InstallCDN' && app.apk_url,
        isAppDetailAccessable: isAppDetailAccessable(app),
      }
    })
  return rotationBanners
}

/**
 * 侧边栏数据适配->sidepanel
 * @param {array<app>} param0
 * @returns array<app>
 */
export const sidepanelDataTransformer = ({ apps }) => {
  const sidepanelData = apps.map((app) => {
    return {
      ...app,
      // client必须
      package_name: app.app_pkg,
      game_name: app.app_name,
      cloud_app_url: cloudGameUrlAdapt(app.cloud_app_url),
      cloud_supported: app.cloud_supported,
      icon_url: getIconUrlByStrategy({
        action: app.action,
        packageName: app.app_pkg,
        iconUrl: app.banner_url,
      }),
      // webs使用
      banner_url: app.banner_url,
      video_url: app.video_url,
      showCloudBtn: !!app.cloud_supported,
      showPlayBtn: true,
      showPcBtn: false,
      // 动作为浏览器打开（配置不可点击详情页操作）
      isBrowserAction: ['ApplicationBrowser', 'UserBrowser'].includes(app.action),
      // 个性化按钮文案(不显示availableNow)
      isOpenInBrowser: ['ApplicationBrowser', 'UserBrowser', 'OpenGooglePlayPage'].includes(
        app.action
      ),
      isInstallCDN: app.action === 'InstallCDN' && app.apk_url,
      isAppDetailAccessable: isAppDetailAccessable(app),
    }
  })
  return { apps: sidepanelData }
}

/**
 * 推荐数据适配->[recommendedApps,hotApps,topApps,topics]
 * @param {lastest_game,hot_list,selected_game,zhuti} data
 * @returns object
 */
export const recommendedAppsTransformer = (data) => {
  const { latest_game, hot_list, selected_game, zhuanti } = data
  const recommendedApps = getGameCenterAppAdapt(latest_game)
  const selectedList = getGameCenterAppAdapt(selected_game)
  const hotList = getGameCenterAppAdapt(hot_list)

  return {
    recommendedApps: recommendedApps,
    hotApps: hotList,
    topApps: selectedList,
    topics: zhuanti,
  }
}

/**
 * 云游戏数据适配
 * @param {array<app>} apps
 * @returns array<app>
 */
export const cloudGanmeAppsTransformer = (apps) => {
  const cloudGames = apps.map((app) => {
    // 云游戏链接
    const playPageUrl = cloudGameUrlAdapt(app.playPageUrl)
    return {
      ...app,
      // client必须
      game_name: app.name,
      package_name: app.packageName,
      cloud_app_url: playPageUrl,
      cloud_supported: true,
      icon_url: app.media.desktop?.icon,
      game_play_preference: ['cloud'],
      // webs使用
      category: app.genre,
      id: app.packageName,
      banner_url: app.media.desktop?.banner,
      ogimage_url: app.media.desktop?.ogimage,
      showCloudBtn: true,
      showPlayBtn: false,
      showPcBtn: false,
    }
  })
  return {
    cloudGames: cloudGames,
    isFetchSuccess: true,
  }
}

/**
 * 查询接口适配
 * @param {{result:array<app>}} data
 * @returns array<app>
 */
export const searchResultsTransformer = (data) => {
  const {
    result: { search_result },
  } = data
  const searchResults = search_result.map((app) => {
    const steamSupported = app.source === 'steam'
    const category = (app.genre && app.genre[0]) || (app.type && app.type[0])
    const iconUrl = steamSupported
      ? app.img_url
      : getIconUrlByStrategy({
          action: app.action,
          packageName: app.package_name,
          iconUrl: app.icon_url,
        })
    return {
      ...app,
      // client必须
      game_name: app.game_name || app.app_name,
      icon_url: iconUrl,
      package_name: app.package_name,
      cloud_app_url: cloudGameUrlAdapt(app.cloud_app_url),
      cloud_supported: app.cloud_supported,
      // webs使用
      banner_url: app.rotation_image_url,
      banner_url_v: app.game_tile_url,
      category: category,
      steam_supported: steamSupported,
      steam_appp_url: steamSupported ? `https://store.steampowered.com/app/${app.steam_id}` : null,
      showCloudBtn: !!app.cloud_supported,
      showPlayBtn: !steamSupported,
      showPcBtn: steamSupported,
      // 动作为浏览器打开（配置不可点击详情页操作）
      isBrowserAction: ['ApplicationBrowser', 'UserBrowser'].includes(app.action),
      // 个性化按钮文案(不显示availableNow)
      isOpenInBrowser: ['ApplicationBrowser', 'UserBrowser', 'OpenGooglePlayPage'].includes(
        app.action
      ),
      isInstallCDN: app.action === 'InstallCDN' && app.apk_url,
      isAppDetailAccessable: isAppDetailAccessable(app),
    }
  })
  return searchResults
}

/**
 * 特色数据适配
 * @param {array<app>} apps
 * @returns array<app>
 */
export const featureAppsDataTransformer = (apps) => {
  const transformData = apps.map((app) => {
    return {
      ...app,
      // client必须
      package_name: app.package_name,
      cloud_app_url: cloudGameUrlAdapt(app.cloud_app_url),
      cloud_supported: app.cloud_supported,
      icon_url: getIconUrlByStrategy({
        action: app.action,
        packageName: app.package_name,
        iconUrl: app.icon_url,
      }),
      game_name: app.game_name,
      // webs使用
      game_tile: app.banner_url,
      platform_name: app.platform_name,
      showCloudBtn: !!app.cloud_supported,
      showPlayBtn: true,
      showPcBtn: false,
      // 动作为浏览器打开（配置不可点击详情页操作）
      isBrowserAction: ['ApplicationBrowser', 'UserBrowser'].includes(app.action),
      // 个性化按钮文案(不显示availableNow)
      isOpenInBrowser: ['ApplicationBrowser', 'UserBrowser', 'OpenGooglePlayPage'].includes(
        app.action
      ),
      isInstallCDN: app.action === 'InstallCDN' && app.apk_url,
      isAppDetailAccessable: isAppDetailAccessable(app),
    }
  })
  return transformData
}

/**
 * 专题Apps数据适配
 * @param {array<app>} apps
 * @returns array<app>
 */
export const topicAppsDataTransformer = (apps) => {
  const transformData = apps
    .filter((app) => app.id && app.title)
    .map((app) => {
      return {
        ...app,
        // client必须
        package_name: app.id,
        game_name: app.title,
        cloud_app_url: cloudGameUrlAdapt(app.cloud_app_url),
        cloud_supported: app.cloud_supported,
        icon_url: getIconUrlByStrategy({
          action: app.action,
          packageName: app.id,
          iconUrl: getSpecialSizeIconByReplace(app.icon_url),
        }),
        // webs使用
        platform_name: app.platform_name,
        game_tile: app.game_tile_url,
        showCloudBtn: !!app.cloud_supported,
        showPlayBtn: true,
        showPcBtn: false,
        // 动作为浏览器打开（配置不可点击详情页操作）
        isBrowserAction: ['ApplicationBrowser', 'UserBrowser'].includes(app.action),
        // 个性化按钮文案(不显示availableNow)
        isOpenInBrowser: ['ApplicationBrowser', 'UserBrowser', 'OpenGooglePlayPage'].includes(
          app.action
        ),
        isInstallCDN: app.action === 'InstallCDN' && app.apk_url,
        isAppDetailAccessable: isAppDetailAccessable(app),
      }
    })
  return transformData
}

// app detail游戏数据适配
export const appDetailDataTransformer = (apps) => {
  const transformData = apps.map((app) => {
    return {
      ...app,
      // client必须
      package_name: app.package_name,
      icon_url: getIconUrlByStrategy({
        action: app.action,
        packageName: app.package_name,
        iconUrl: app.icon_url,
      }),
      cloud_app_url: '',
      cloud_supported: false,
      game_name: app.title,
      // webs使用
      platform_name: app.developer_name,
      showCloudBtn: !!app.cloud_supported,
      showPlayBtn: true,
      showPcBtn: false,
      category: app.app_genre,
      // 动作为浏览器打开（配置不可点击详情页操作）
      isBrowserAction: ['ApplicationBrowser', 'UserBrowser'].includes(app.action),
      // 个性化按钮文案(不显示availableNow)
      isOpenInBrowser: ['ApplicationBrowser', 'UserBrowser', 'OpenGooglePlayPage'].includes(
        app.action
      ),
      isInstallCDN: app.action === 'InstallCDN' && app.apk_url,
      isAppDetailAccessable: isAppDetailAccessable(app),
      game_play_preference: ['local'],
    }
  })
  return transformData
}
