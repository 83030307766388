import { combineReducers } from 'redux'
import appReducer from './app/reducer'
import systemReducer from './system/reducer'
import userReducer from './user/reducer'
import cloudReducer from './cloud/reducer'
import statusReducer from './system/reducer'
import playerlistReducer from './playerlist/reducer'
import appdetailReducer from './appdetail/reducer'

const reducer = combineReducers({
  app: appReducer,
  system: systemReducer,
  user: userReducer,
  cloud: cloudReducer,
  status: statusReducer,
  playerlist: playerlistReducer,
  appdetail: appdetailReducer,
})

export default reducer
