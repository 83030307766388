import React, { useEffect } from 'react'
import classNames from 'classnames'
import { BrowserRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { useDispatch, useSelector } from 'react-redux'
import routes from '@/router'
import Setup from '@/components/setup/Index'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import { isQtClient } from '@/tool/qwebchannel/utils'

import CommonAnimation from '@/components/common/Animation/CommonAnimation'

// actions
import { initGameCenterApps, initCloudGamesApps } from '@/reducers/app/actions'
import { fetchCountryCode } from '@/reducers/system/actions'

const App = () => {
  const dispatch = useDispatch()
  const theme = useSelector((state) => state.user.theme)
  const countryCode = useSelector((state) => state.system.countryCode)
  const clientMode = useSelector((state) => state.system.mode)
  const localeCode = useSelector((state) => state.system.localeCode)
  const showOfflinePage = useSelector((state) => state.status.showOfflinePage)

  // 获取国家码
  useEffect(() => {
    dispatch(fetchCountryCode())
  }, [dispatch])

  // jun's game数据请求
  useEffect(() => {
    if (countryCode === 'JP') {
      dispatch(initCloudGamesApps({}))
    }
  }, [dispatch, localeCode, countryCode, clientMode])

  useEffect(() => {
    // 数据config加载
    if (countryCode) {
      dispatch(initGameCenterApps())
    }
  }, [dispatch, countryCode, clientMode])

  // 阻止tab键功能
  useEffect(() => {
    function onKeyDownHandler(evt) {
      if (evt.keyCode === 9) {
        if (evt.preventDefault) {
          evt.preventDefault()
        } else {
          evt.returnValue = false
        }
      }
    }
    window.addEventListener('keydown', onKeyDownHandler)
    return () => {
      window.removeEventListener('keydown', onKeyDownHandler)
    }
  }, [])

  return (
    <div id="appRoot" className={`${theme}`}>
      <ErrorFallback theme={theme} pageError={true}>
        <div className={classNames(['container', { isQtClient }])}>
          <BrowserRouter>
            <Setup />
            {showOfflinePage ? null : <CommonAnimation>{renderRoutes(routes)}</CommonAnimation>}
          </BrowserRouter>
        </div>
      </ErrorFallback>
    </div>
  )
}

export default App
