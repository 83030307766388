import React, { useEffect, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import GameList from '@/components/GameList/Index'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import SubHeadingStrip from '@/components/common/SubHeadingStrip/Index'
import { getSearchResultTransformer } from '@/transformers/transformerApi'
import { switchLoader } from '@/reducers/system/actions'

import googlePlayIcon from '@/images/google.svg'

import { STAT_SEARCH_PAGE_RESULT } from '@/tool/constant'
import Utils from '@/Utils'
import { qwebApi } from '@/apis/qwebApi'
import stats, { TABLE_TYPE, STATS_POINT } from '@/tool/stats'
import style from './index.module.scss'

const SearchResults = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const query = new URLSearchParams(location.search).get('query')
  const { isFetching, data, isSuccess, isFetched } = useQuery(
    ['searchQuery', query],
    getSearchResultTransformer,
    {
      initialData: [],
      enabled: !!query,
    }
  )

  const onBack = () => {
    history.replace({
      pathname: 'home',
    })
  }
  const onSearchInGooglePlayClick = useCallback(() => {
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK_V2, false, {
      event_type: STATS_POINT.BSX_SEARCH_IN_GP_CLICKED,
      query,
      banner_location: STAT_SEARCH_PAGE_RESULT,
    })
    qwebApi({ event: 'onSearchInGooglePlay', data: { query } })
  }, [query])

  useEffect(() => {
    dispatch(switchLoader(isFetching))
  }, [isFetching, dispatch])
  if (isFetching) {
    return null
  }
  const itemsCount = data.length
  return (
    <div className={style.container}>
      <GameList
        gameItemProps={{
          imgPlaceholderClass: 'ratio-1-1',
          showPlayBtn: true,
          showCloudBtn: true,
          showInstalledState: true,
          showTypeIcon: true,
          showPcBtn: true,
          showCsIcon: true,
          bannerLocation: STAT_SEARCH_PAGE_RESULT,
        }}
        list={data}
        contentInnerClass={['breakScreen', 'marginBottom30']}
        columns={6}
        customTitleRender={() => (
          <SubHeadingStrip
            className="breakScreen"
            onBack={onBack}
            style={{ paddingBottom: `${Utils.getRemSizeByResponseSise(20)}rem` }}
            title={
              <>
                <FormattedMessage id="searchResultsFor" />
                <span className="margin-left-5">
                  "{query.length > 20 ? `${query.substring(0, 20)}...` : query}"
                </span>
              </>
            }
            count={itemsCount}
          />
        )}
        prefix={(list) => {
          let nofoundJsx = null
          if (list.length === 0 && isFetched) {
            nofoundJsx = (
              <CommonHelpTip
                textTitle={isSuccess ? 'noFoundBySearch' : 'noInternetConnect'}
                imgSrc={isSuccess ? 'noResults' : 'noNetwork'}
                localSvg
              />
            )
          }
          return (
            <div
              className={style.helpBox}
              style={{ height: list.length === 0 ? 'calc(100% - 2px)' : 'unset' }}
            >
              {nofoundJsx}
              <div
                className={style.searchInGooglePlay}
                style={!nofoundJsx ? { marginTop: '0px', marginBottom: '30px' } : {}}
                onClick={onSearchInGooglePlayClick}
              >
                <div className={style.searchInGooglePlayInner}>
                  <img src={googlePlayIcon} alt="Search in google play" />
                  <FormattedMessage id="searchInGooglePlay" />
                </div>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

export default React.memo(SearchResults)
