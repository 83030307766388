import { useState, useEffect, useCallback } from 'react'
import { on, off, qwebApi } from '@/apis/qwebApi'

const Hook = () => {
  const [csGames, setCsGames] = useState([])
  useEffect(() => {
    qwebApi({ event: 'getCsGames', callbackEvent: 'ModedGameListChanged' })
  }, [])
  // 更新回调
  const onReceiverCsApps = useCallback((jsonObject) => {
    try {
      if (jsonObject) {
        setCsGames(jsonObject.data || [])
      }
    } catch (error) {
      setCsGames([])
    }
  }, [])
  // 监听最近游戏变化
  useEffect(() => {
    on('ModedGameListChanged', onReceiverCsApps)
    return () => {
      off('ModedGameListChanged', onReceiverCsApps)
    }
  }, [onReceiverCsApps])
  return csGames
}

export default Hook
