const colors = [
  {
    background: 'linear-gradient(120deg, #FF1D25 0%, #49A7E8 100%)',
  },
  {
    background: 'linear-gradient(120deg, #F4D000 0%, #5BCE66 100%)',
  },
  {
    background: 'linear-gradient(120deg, #49A7E8 0%, #5BCE66 100%)',
  },
  {
    background: 'linear-gradient(120deg, #5BCE66 0%, #49A7E8 100%)',
  },
  {
    background: 'linear-gradient(120deg, #FF1D25 0%, #F4D000 100%)',
  },
  {
    background: 'linear-gradient(120deg, #49A7E8 0%, #FF1D25 100%)',
  },
  {
    background: 'linear-gradient(120deg, #49A7E8 0%, #F4D000 100%)',
  },
]

export default colors
