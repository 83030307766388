import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { setLanguage } from '@/reducers/user/actions'
import { setLocalCode } from '@/reducers/system/actions'
import messages_en from '../json/i18n/en-US.json'
import { on, off, qwebApi } from '@/apis/qwebApi'

const LanguageMapClient = {
  en: 'en-US',
  ar: 'ar-EG',
  // chs: 'zh-CN',
  cht: 'zh-TW',
  // nl: 'nl-NL',
  fr: 'fr-FR',
  de: 'de-DE',
  id: 'id-ID',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  // ms: 'ms-MY',
  pl: 'pl-PL',
  pt: 'pt-BR',
  ru: 'ru-RU',
  es: 'es-ES',
  th: 'th-TH',
  tr: 'tr-TR',
  vi: 'vi-VN',
}

// 加载字体
const loadFont = async (name, path) => {
  const fontList = [...document.fonts]
  try {
    const isExist = fontList.find((font) => font.family === name)
    if (!isExist) {
      const font = new FontFace(name, `url(${process.env.PUBLIC_URL}fonts/${path})`, {
        style: 'normal',
      })
      await font.load()
      document.fonts.add(font)
    }
    document.body.style.fontFamily = name
  } catch (error) {
    console.log(error)
  }
}

// 加载语言包
async function loadI18N(languages) {
  let messages = {}
  let isOk = false
  let language = languages.shift()
  let languageWeb
  while (language && !isOk) {
    languageWeb = LanguageMapClient[language]
    try {
      messages = require(`../json/i18n/${languageWeb}.json`)
      isOk = true
    } catch (error) {
      const nextLanguage = languages.shift()
      console.log(`${language}：加载语言包失败,切换至语言${nextLanguage}`)
      language = nextLanguage
    }
  }
  return { language, languageWeb, messages }
}

const Hook = ({ children }) => {
  const dispatch = useDispatch()
  const language = useSelector((state) => state.user.language)
  const [messages, setMessages] = useState(() => {
    // try {
    //   const msg = await import(`@/json/i18n/${language || 'en-US'}.json`)
    //   return msg
    // } catch (error) {
    //   return messages_en
    // }
    return messages_en
  })
  // 切换语言
  const onUpdateLanguage = useCallback(
    async ({ language, defaultLanguage }) => {
      try {
        const loadLanguage = await loadI18N([language, defaultLanguage, 'en'].filter(Boolean))
        dispatch(setLanguage(loadLanguage.languageWeb))
        dispatch(setLocalCode(loadLanguage.language))
        setMessages(loadLanguage.messages)
        switch (loadLanguage.languageWeb) {
          case 'ko-KR':
            loadFont('Malgun', 'malgun.ttf')
            break
          case 'ja-JP':
            loadFont('Yugothib', 'yugothib.ttf')
            break
          // case 'en-US':
          //   loadFont('Roboto', 'roboto.ttf')
          //   break
          case 'zh-CN':
          case 'zh-TW':
            loadFont('Msyh', 'msyh.ttc')
            break
          default:
            loadFont('Rubik', 'Rubik-Regular.ttf')
        }
      } catch (error) {}
    },
    [dispatch]
  )
  useEffect(() => {
    qwebApi({ event: 'getLanguage', callbackEvent: 'updateLanguage' })
  }, [])
  // 监听语言变化
  useEffect(() => {
    on('updateLanguage', onUpdateLanguage)
    return () => {
      off('updateLanguage', onUpdateLanguage)
    }
  }, [onUpdateLanguage])
  return (
    <IntlProvider locale={language} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export default Hook
