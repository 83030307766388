import React from 'react'
import { useSelector } from 'react-redux'
import GameCarousel from '@/components/GameList/GameCarousel'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import GameList from '@/components/GameList/Index'

// import { arrayShuffle } from '@/tool'

import {
  STAT_HOME_PAGE_TRENDINGGAMES,
  STAT_HOME_PAGE_POPULARGAMES,
  STAT_HOME_PAGE_JUNGAMES,
} from '@/tool/constant'
// import { useMemo } from 'react'

const List = () => {
  const countryCode = useSelector((state) => state.system.countryCode)
  // 云游戏
  const cloudApps = useSelector((state) => state.cloud.cloudGames) || []
  // 置顶游戏（selected_game）
  const topApps = useSelector((state) => state.app.topApps)
  // // 最新游戏（latest_game）
  // const recommendedApps = useSelector((state) => state.app.recommendedApps) || []

  // 火热游戏（hot_list）
  const hotApps = useSelector((state) => state.app.hotApps)

  // const shuffleTopApps = useMemo(() => {
  //   return arrayShuffle([...topApps])
  // }, [topApps])

  // const shuffleHotApps = useMemo(() => {
  //   return arrayShuffle([...hotApps])
  // }, [hotApps])
  return (
    <div className="homeList breakScreen">
      {/* cloud */}
      {countryCode === 'JP' && (
        <ErrorFallback>
          <GameCarousel
            carouselKeyId="homeCloudGames"
            titleClass="spaceBetween"
            title="加藤純一アプリ王選手権作品"
            linkTo={{
              pathname: 'apps',
              search: 'type=cloudGames&from=home&title=junGames',
            }}
            list={cloudApps}
            imgPlaceholderClass="ratio-1-1"
            bannerLocation={STAT_HOME_PAGE_JUNGAMES}
            showCloudBtn
            showPlayBtn
            slidesPerView={6}
            isGroup={false}
          />
        </ErrorFallback>
      )}
      {/* hot-Popular Games in your region */}
      <ErrorFallback>
        <GameCarousel
          carouselKeyId="homeHotGames"
          titleClass="spaceBetween"
          titleId="androidGamesLike"
          list={hotApps}
          className="margin-bottom-30"
          linkTo={{
            pathname: 'apps',
            search: 'type=hotApps&title=androidGamesLike&from=home',
          }}
          showPlayBtn
          showCloudBtn
          bannerLocation={STAT_HOME_PAGE_POPULARGAMES}
          imgPlaceholderClass="ratio-3-4"
          imageUrlGet={(game) => (countryCode === 'JP' ? game.icon_url : game.banner_url_v)}
          showInstalledState
          slidesPerView={5}
          isGroup={false}
        />
      </ErrorFallback>
      {/* top-Trending Games */}
      <ErrorFallback>
        <GameCarousel
          carouselKeyId="homeTopGames"
          titleClass="spaceBetween"
          titleId="cloudGamesLike"
          linkTo={{
            pathname: 'apps',
            search: 'type=topApps&from=home&title=cloudGamesLike',
          }}
          list={topApps}
          showInstalledState
          imgPlaceholderClass="ratio-1-1"
          bannerLocation={STAT_HOME_PAGE_TRENDINGGAMES}
          showCloudBtn
          showPlayBtn
          slidesPerView={1}
          groupNumber={12}
          renderItem={({ slide, itemProps }) => {
            return (
              <GameList
                contentInnerClass="marginBottom30"
                gameItemProps={itemProps}
                list={slide}
                columns={6}
              />
            )
          }}
        />
      </ErrorFallback>
    </div>
  )
}

export default React.memo(List)
