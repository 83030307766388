import * as types from './constant'
import { fetchCloudGameApps } from '../cloud/actions'
import {
  rotationDataTransformer,
  recommendedAppsTransformer,
  searchResultsTransformer,
  sidepanelDataTransformer,
  featureAppsDataTransformer,
  topicAppsDataTransformer,
} from '@/transformers'
import {
  getRotationAppsApi,
  getHomeAppsApi,
  getSearchAppsApi_v4,
  getSidepanelAppsApi,
  getFeatureAppsApi,
  getTopicInfo,
} from '@/apis/apps'

import { errorLog } from '@/tool'
import { switchLoader } from '../system/actions'

import { qwebApi } from '@/apis/qwebApi'

import logger from '@/tool/logger'

// 更新本地安装游戏
export const setInstalledApps = (apps) => {
  return {
    type: types.SET_INSTALLED_APPS,
    payload: apps,
  }
}

// appcenter/game_center_rotation--轮播数据request
export const fetchRotationApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchRotationApps')
    try {
      const _result = await getRotationAppsApi()
      dispatch({
        type: types.FETCH_ROTATION_APPS,
        payload: rotationDataTransformer(_result.rotation),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchRotationApps')
  }
}

// appcenter/game_center_home--gameCenter数据request
export const fetchGameCenterApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchGameCenterApps')
    try {
      const _result = await getHomeAppsApi()
      dispatch({
        type: types.FETCH_GAME_CENTER_HOME_APPS,
        payload: recommendedAppsTransformer(_result),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchGameCenterApps')
  }
}

// 混淆recommend数据
export const shuffleRecommendedApps = (apps) => {
  return {
    type: types.SHUFFLE_RECOMMENDED_APPS,
    payload: apps,
  }
}

// 获取feature数据
export const fetchFeaturedApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchFeatureApps')
    try {
      const _result = await getFeatureAppsApi()
      dispatch({
        type: types.FETCH_FEATURED_APPS,
        payload: featureAppsDataTransformer(_result),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchFeatureApps')
  }
}

// 获取专题数据
export const fetchTopicApps = (topic_id, isForce) => {
  return async (dispatch, getState) => {
    if (!isForce) {
      const topicIdApps = getState().app.topicAppsMap[topic_id]
      if (topicIdApps) {
        return topicIdApps
      }
    }
    logger('start', 'fetchTopicApps')
    dispatch(switchLoader())
    try {
      const { result } = await getTopicInfo({ topic_id })
      const apps = topicAppsDataTransformer(result.topic_game)
      dispatch({
        type: types.FETCH_TOPIC_APPS,
        payload: { apps, topic_id, isFetchSuccess: true },
      })
    } catch (error) {
      errorLog(error)
      dispatch({
        type: types.FETCH_TOPIC_APPS,
        payload: { apps: null, topic_id, isFetchSuccess: false },
      })
    }
    dispatch(switchLoader(false))
    logger('finish', 'fetchTopicApps')
  }
}

// 设置专题数据
export const setTopicApps = ({ data, topicId }) => {
  const apps = topicAppsDataTransformer(data.topic_game || [])
  return {
    type: types.SET_TOPIC_APPS,
    payload: { apps, topicId },
  }
}

// app搜索
export const fetchSearchApps = ({ query }) => {
  return async (dispatch) => {
    logger('start', 'fetchSearchApps')
    dispatch(switchLoader())
    try {
      const _result = await getSearchAppsApi_v4(query, true)
      dispatch({
        type: types.FETCH_SEARCH_RESULTS,
        payload: searchResultsTransformer(_result),
      })
    } catch (error) {
      // debugger
      errorLog(error)
    }
    dispatch(switchLoader(false))
    logger('finish', 'fetchSearchApps')
  }
}

// 获取侧边栏数据
export const fetchSidepanelApps = () => {
  return async (dispatch) => {
    logger('start', 'fetchSidepanelApps')
    try {
      const _result = await getSidepanelAppsApi()
      return dispatch({
        type: types.FETCH_SIDEPANEL_APPS,
        payload: sidepanelDataTransformer(_result.data),
      })
    } catch (error) {
      errorLog(error)
    }
    logger('finish', 'fetchSidepanelApps')
  }
}

// 获取GameCenter
export const initGameCenterApps = () => {
  return async (dispatch) => {
    dispatch(switchLoader())
    logger('start', 'preCoreDataLoad')
    try {
      dispatch(fetchFeaturedApps())
      await Promise.all([dispatch(fetchRotationApps()), dispatch(fetchGameCenterApps())])
    } catch (error) {
      console.log('initGameCenterApps:', error)
    }
    logger('finish', 'preCoreDataLoad')
    dispatch(switchLoader(false))
    setTimeout(() => {
      qwebApi({ event: 'closeBootAnimation' })
    }, 0)
    logger('finish', 'showWebPage')
  }
}

// 获取CloudPage
export const initCloudGamesApps = ({ showLoading = false }) => {
  return async (dispatch) => {
    try {
      await dispatch(fetchCloudGameApps({ showLoading }))
    } catch (error) {
      console.log('initCloudGamesApps:', error)
    }
  }
}

// 配置cs apps
export const setCsApps = (apps) => {
  return {
    type: types.SET_CS_APPS,
    payload: apps,
  }
}

// 配置local perference
export const setLocalPerference = (perference) => {
  return {
    type: types.SET_LOCAL_PERFERENCE,
    payload: perference,
  }
}
