import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GameList from '@/components/GameList/Index'
import GameCarousel from '@/components/GameList/GameCarousel'
import Collections from '@/components/common/Collection/Index'
import SectionCarousel from '@/components/AdvertisementSection/SectionCarousel'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'

import { shuffleRecommendedApps } from '@/reducers/app/actions'

// import ImaContainer from '@/components/Ima/ImaContainer'

import { arrayShuffle } from '../../tool'
import {
  STAT_GAMECENTER_PAGE_RECOMMENDED,
  STAT_GAMECENTER_PAGE_FEATURE,
  STAT_GAMECENTER_PAGE_MUSTPLAYGAMES,
  STAT_GAMECENTER_PAGE_NEWANDTRENDING,
} from '@/tool/constant'

const List = ({ isActive }) => {
  const dispatch = useDispatch()
  const countryCode = useSelector((state) => state.system.countryCode)

  // 置顶游戏（selected_game）
  const topApps = useSelector((state) => state.app.topApps.slice(0, 13))

  // 火热游戏（hot_list）
  const hotApps = useSelector((state) => state.app.hotApps.slice(0, 19))

  // feature app
  const featureApps = useSelector((state) => state.app.feature.list)

  // 最新游戏（latest_game）
  const recommendedApps = useSelector((state) => state.app.recommendedApps)

  const onRefresh = useCallback(() => {
    dispatch(shuffleRecommendedApps(arrayShuffle([...recommendedApps])))
  }, [recommendedApps, dispatch])

  const recommendedAppsForShow = useMemo(() => {
    if (countryCode === 'JP') {
      return recommendedApps.slice(0, 6)
    }
    const topApps = [...recommendedApps].filter((app, o) => {
      return !!app.banner_url_v
    })
    const lastApps = [...recommendedApps].filter((app, o) => {
      return !app.banner_url_v
    })
    return topApps.concat(lastApps).slice(0, 6)
  }, [recommendedApps, countryCode])

  return (
    <div className="gameCenterList breakScreen">
      {/* Recommended For You */}
      {recommendedApps.length ? (
        <ErrorFallback>
          <GameCarousel
            className={{ hideHeader: !isActive }}
            carouselKeyId="recommendedGames"
            titleId="recommendedGames"
            bannerLocation={STAT_GAMECENTER_PAGE_RECOMMENDED}
            list={recommendedAppsForShow}
            linkTo={{
              pathname: 'apps',
              search: 'type=recommendedApps&from=gameCenter',
            }}
            onRefresh={onRefresh}
            pagination={false}
            showPlayBtn
            showCloudBtn
            imgPlaceholderClass="ratio-3-4"
            imageUrlGet={(game) => (countryCode === 'JP' ? game.icon_url : game.banner_url_v)}
            showInstalledState
            slidesPerView={5}
            isGroup={false}
          />
        </ErrorFallback>
      ) : null}
      {/* featureApps */}
      {featureApps.length ? (
        <ErrorFallback>
          <SectionCarousel
            imgPlaceholderClass="ratio-3-1"
            list={featureApps}
            title="appOfTheDay"
            bannerLocation={STAT_GAMECENTER_PAGE_FEATURE}
            showInstalledState
            className="margin-top-60 margin-bottom-30"
          />
        </ErrorFallback>
      ) : null}

      {/* google ima AD */}
      {/* <ImaContainer /> */}
      <ErrorFallback>
        <Collections title="browseByCollection" className="margin-bottom-30" wrap />
      </ErrorFallback>
      {/* Must-Play Games */}
      {topApps.length ? (
        <ErrorFallback>
          <GameCarousel
            titleId="topGames"
            list={topApps}
            carouselKeyId="topGames"
            bannerLocation={STAT_GAMECENTER_PAGE_MUSTPLAYGAMES}
            linkTo={{
              pathname: 'apps',
              search: 'type=topApps&from=gameCenter',
            }}
            imgPlaceholderClass="ratio-1-1"
            showPlayBtn
            showCloudBtn
            showInstalledState
            pagination={false}
            slidesPerView={1}
            groupNumber={12}
            renderItem={({ slide, itemProps }) => {
              return <GameList gameItemProps={itemProps} list={slide} columns={6} />
            }}
          />
        </ErrorFallback>
      ) : null}
      {/* New and Trending */}
      {hotApps.length ? (
        <ErrorFallback>
          <GameCarousel
            titleId="popularGames"
            carouselKeyId="popularGames"
            bannerLocation={STAT_GAMECENTER_PAGE_NEWANDTRENDING}
            list={hotApps}
            className="gameList"
            linkTo={{
              pathname: 'apps',
              search: 'type=hotApps&from=gameCenter',
            }}
            pagination={false}
            imgPlaceholderClass="ratio-1-1"
            showPlayBtn
            showCloudBtn
            showInstalledState
            slidesPerView={1}
            groupNumber={18}
            renderItem={({ slide, itemProps }) => {
              return (
                <GameList
                  contentInnerClass="marginBottom30"
                  gameItemProps={itemProps}
                  list={slide}
                  columns={6}
                />
              )
            }}
          />
        </ErrorFallback>
      ) : null}
    </div>
  )
}

export default React.memo(List)
