// 移除本地游戏
export const REMOVE_INSTALLED_APPS = 'REMOVE_INSTALLED_APPS'

// 设置本地已安装app
export const SET_INSTALLED_APPS = 'SET_INSTALLED_APPS'

// 设置ratation apps
export const FETCH_ROTATION_APPS = 'FETCH_ROTATION_APPS'

// 设置推荐apps
export const FETCH_GAME_CENTER_HOME_APPS = 'FETCH_GAME_CENTER_HOME_APPS'

// 随机recommended apps
export const SHUFFLE_RECOMMENDED_APPS = 'SHUFFLE_RECOMMENDED_APPS'

// 设置搜索apps
export const FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS'

// 设置sidepanel apps
export const FETCH_SIDEPANEL_APPS = 'FETCH_SIDEPANEL_APPS'

// 设置特色apps
export const FETCH_FEATURED_APPS = 'FETCH_FEATURED_APPS'

// 设置专题apps
export const FETCH_TOPIC_APPS = 'FETCH_TOPIC_APPS'
export const SET_TOPIC_APPS = 'SET_TOPIC_APPS'

// cs apps
export const SET_CS_APPS = 'SET_CS_APPS'

// local perference
export const SET_LOCAL_PERFERENCE = 'SET_LOCAL_PERFERENCE'
