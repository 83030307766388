import React, { useCallback, useRef, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useDebounceFn } from 'ahooks'
import classNames from 'classnames'
import { qwebApi } from '@/apis/qwebApi'
import GameList from '@/components/GameList/GameList'
import ActionButton from '@/components/actionButton/Index'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'

import { onGameClickAction } from '@/actions/commonActions'

import { STAT_HOME_PAGE_BANNER } from '@/tool/constant'

import rotationCloudApps from './useRotationCloudApps'

import style from './banner.module.scss'
const Banner = ({ isActive }) => {
  const dispatch = useDispatch()
  const [hasNextApp, setHasNextApp] = useState(false)
  const rotationBanners = rotationCloudApps()

  const [bannerGame, setBannerGame] = useState(null)
  const bannerRef = useRef(null)

  const localeCode = useSelector((state) => state.system.localeCode)
  const installedApps = useSelector((state) => state.app.installedApps)
  const isInstalled = installedApps.includes(bannerGame?.package_name)

  // 列表入场动画
  const gameListStyle = useMemo(() => {
    const style = {}
    if (!rotationBanners.length || !isActive) {
      style.transform = 'translateX(100%)'
      style.opacity = 0.3
    } else {
      style.transform = 'translateX(0)'
      style.transitionDelay = '0.5s'
    }
    return style
  }, [rotationBanners, isActive])

  // 详细信息入场动画
  const gameDescStyle = useMemo(() => {
    const style = {}
    if (!rotationBanners.length || !isActive) {
      style.transform = 'translateX(-100%)'
      style.opacity = 0.3
    } else {
      style.transform = 'translateX(0)'
      style.transitionDelay = '0.5s'
    }
    return style
  }, [rotationBanners, isActive])
  // 切换banner game
  const onTurnBannerGame = useCallback(
    async (item) => {
      const bannerGamePck = bannerGame?.id
      const bannerGameName = bannerGame?.game_name
      if (bannerGamePck !== item.id || bannerGameName !== item.game_name) {
        setBannerGame({ ...item })
      }
    },
    [bannerGame]
  )

  // 鼠标移入事件
  const onHoverEnter = useCallback(
    (item, ref, index) => {
      ref.current.setActiveIndex(index)
      onTurnBannerGame(item)
    },
    [onTurnBannerGame]
  )

  // 切换banner大图
  useEffect(() => {
    if (bannerGame) {
      if (isActive) {
        qwebApi({
          event: 'changeBannerBackgroundImage',
          data: {
            url: bannerGame.full_hd_image,
            video: bannerGame.video_url,
            playCount: 1,
            sleep: 500,
          },
        })
      } else {
        qwebApi({ event: 'changeBannerBackgroundImage' })
      }
    }
  }, [bannerGame, isActive])

  // 页面初始化
  useEffect(() => {
    if (rotationBanners.length) {
      // onTurnBannerGame(rotationBanners[0])
      setBannerGame(rotationBanners[0])
    }
  }, [rotationBanners])

  // 打开游戏
  const handleOnClick = useCallback(
    ({ evt, gameType, isInstalled, app, actionCode }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        dispatch,
        gameType,
        bannerLocation: STAT_HOME_PAGE_BANNER,
        actionCode,
      })
    },
    [dispatch]
  )

  // 上一个
  const onPrev = useDebounceFn(
    async (ref) => {
      await ref.current.scrollTo(-4 / 5, 'turn')
      const { item } = ref.current.turnStatus
      onTurnBannerGame(item)
    },
    { wait: 50, leading: true }
  )
  // 下一个
  const onNext = useDebounceFn(
    async (ref) => {
      await ref.current.scrollTo(4 / 5, 'turn')
      const { item } = ref.current.turnStatus
      onTurnBannerGame(item)
    },
    { wait: 50, leading: true }
  )
  const bannerDescJsx = useMemo(() => {
    return (
      <div className={style.header} style={gameDescStyle}>
        {bannerGame ? (
          <div className={style.headerBox}>
            <h1 className={style.gameName}>{bannerGame.game_name}</h1>
            {!bannerGame.isOpenInBrowser && (
              <span className={style.gameStatus}>
                <FormattedMessage id="availableNow"></FormattedMessage>
              </span>
            )}
            <p className={style.gameInfo}>
              <span>{bannerGame.category}</span>
              {bannerGame.platform_name ? (
                <>
                  <b className={style.split}></b>
                  <span>{bannerGame.platform_name}</span>
                </>
              ) : null}
            </p>
          </div>
        ) : null}
      </div>
    )
  }, [bannerGame, gameDescStyle])
  return (
    <div className={style.container} key={localeCode}>
      {bannerDescJsx}
      <div className={classNames([style.content, { [style.isLast]: !hasNextApp }])}>
        <div className={style.actionWrap} style={gameDescStyle}>
          <ActionButton app={bannerGame} isInstalled={isInstalled} handleOnClick={handleOnClick} />
        </div>
        <div style={gameListStyle} className={style.contentWrap} ref={bannerRef}>
          <ErrorFallback>
            <GameList
              lazyRoot={() => bannerRef.current}
              layoutRoot={() => bannerRef.current}
              carouselKeyId="homeBanner"
              list={rotationBanners}
              wrapInOneLine={true}
              pagination
              showPlayBtn
              showCloudBtn
              showInstalledState
              imgPlaceholderClass="ratio-1-1"
              bannerLocation={STAT_HOME_PAGE_BANNER}
              numberOfItems={5}
              onHoverEnter={onHoverEnter}
              onPrev={onPrev.run}
              onNext={onNext.run}
              showLikeIncon={false}
              activeType="step"
              customTitleRender={(ref) => {
                if (!ref.current) {
                  return null
                }
                const { hasPrev, hasNext } = ref.current.turnStatus
                setTimeout(() => setHasNextApp(hasNext))
                return (
                  <div className={style.switchContainer}>
                    <div className={style.switchLeft}>
                      <SvgIcon
                        key="prev"
                        size={1}
                        icon="prev"
                        onClick={() => onPrev.run(ref)}
                        className={[style.switchIcon, { [style.isDisabled]: !hasPrev }]}
                      />
                    </div>
                    <div className={style.switchRight}>
                      <SvgIcon
                        key="next"
                        size={1}
                        icon="next"
                        onClick={() => onNext.run(ref)}
                        className={[style.switchIcon, { [style.isDisabled]: !hasNext }]}
                      />
                    </div>
                  </div>
                )
              }}
              hasHoverAction={true}
            />
          </ErrorFallback>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Banner)
