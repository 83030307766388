import React from 'react'
import { useSelector } from 'react-redux'
import SubHeadingStrip from '@/components/common/SubHeadingStrip/Index'
import CollectionList from '@/components/common/Collection/List'
const Collections = () => {
  const topics = useSelector((state) => state.app.topics)
  return (
    <CollectionList
      list={topics}
      className="breakScreen"
      customTitleRender={() => {
        return <SubHeadingStrip titleId="browseByCollection" />
      }}
    />
  )
}

export default React.memo(Collections)
