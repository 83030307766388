/**
 * 检测网络状态变化
 */

import { useNetwork, usePrevious } from 'ahooks'
const Hook = () => {
  const { online } = useNetwork()
  const prevIOnline = usePrevious(online)
  return [online, prevIOnline === undefined ? online : prevIOnline]
}

export default Hook
