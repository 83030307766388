import { useState, useEffect, useCallback } from 'react'
import { on, off, qwebApi } from '@/apis/qwebApi'

const Hook = () => {
  const [userInfo, setUserInfo] = useState({})
  useEffect(() => {
    qwebApi({ event: 'getUserToken', callbackEvent: 'updateUserToken' })
  }, [])
  // 更新回调
  const onUpdateUserToken = useCallback((jsonObject) => {
    try {
      if (jsonObject) {
        setUserInfo(jsonObject)
      }
    } catch (error) {
      setUserInfo({})
    }
  }, [])
  // 监听最近游戏变化
  useEffect(() => {
    on('updateUserToken', onUpdateUserToken)
    return () => {
      off('updateUserToken', onUpdateUserToken)
    }
  }, [onUpdateUserToken])
  return userInfo
}

export default Hook
