import { useEffect, useState, useCallback } from 'react'
import { on, off, qwebApi } from '@/apis/qwebApi'
const Hook = () => {
  const [installedApps, setInstalledApps] = useState([])
  // 获取初始化数据
  useEffect(() => {
    qwebApi({ event: 'getInstalledApps', callbackEvent: 'updateInstalledAppsNotification' })
  }, [])
  // 更新回调
  const onUpdateInstalledAppsNotification = useCallback(
    (jsonObject) => {
      try {
        const { action, package_name, apps } = jsonObject
        const isExist = installedApps.includes(package_name)
        let allApps = [...installedApps]
        if (action === 'install' && !isExist) {
          allApps.push(package_name)
        }
        if (action === 'uninstall' && isExist) {
          allApps = allApps.filter((name) => name !== package_name)
        }
        if (action === 'all') {
          allApps = apps.map((app) => app.package_name)
        }
        setInstalledApps(allApps)
      } catch (error) {}
    },
    [installedApps]
  )
  // 检测安装数据更新
  useEffect(() => {
    on('updateInstalledAppsNotification', onUpdateInstalledAppsNotification)
    return () => {
      off('updateInstalledAppsNotification', onUpdateInstalledAppsNotification)
    }
  }, [onUpdateInstalledAppsNotification])
  return installedApps
}
export default Hook
