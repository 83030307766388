// 账户信息
export const SET_USER_INFO = 'SET_USER_INFO'
// 账户其他信息
export const SET_PREMIUM_ACCOUNT = 'SET_PREMIUM_ACCOUNT'
// 用户主题
export const SET_THEME = 'SET_THEME'
// APP语言
export const SET_APP_LANGUAGE = 'SET_APP_LANGUAGE'
// client用户信息
export const CLIENT_USER_CONFIG = 'CLIENT_USER_CONFIG'
