import React from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
const SubHeadingStrip = ({ title, titleId, count, onBack, className }) => {
  const history = useHistory()
  const _onBack = () => {
    if (onBack) {
      onBack()
    } else {
      history.goBack()
    }
  }
  return (
    <div
      className={classNames([
        'flex',
        'heading',
        'sectionHeading',
        'spaceBetween',
        'padding-bottom-30',
        'padding-top-30',
        className,
      ])}
    >
      <div className="flex vrtlCenter pointer" onClick={_onBack}>
        <SvgIcon icon="arrow-left" className={['pointer', 'margin-right-10']} />
        {titleId ? <FormattedMessage id={titleId} /> : <span>{title}</span>}
      </div>
      {count > 0 && (
        <div className="font14 flex vrtlCenter padding-right-5">
          <FormattedMessage
            id={count === 1 ? 'subHeadingGameCountForSingle' : 'subHeadingGameCount'}
            values={{ count }}
          />
        </div>
      )}
    </div>
  )
}

export default SubHeadingStrip
