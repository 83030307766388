import produce from 'immer'
import * as types from './constant'
// 主题列表
const themeMap = {
  lighter: 'lighter',
}

// 主題
export const THEME_STORAGE_KEY = 'THEME_STORAGE_KEY'
// 语言
export const LANGUAGE_STORAGE_KEY = 'LANGUAGE_STORAGE_KEY'

const INIT_STATE = {
  userInfo: {},
  theme: themeMap[window.localStorage.getItem(THEME_STORAGE_KEY)] || 'lighter',
  language: window.localStorage.getItem(LANGUAGE_STORAGE_KEY) || 'en-US',
  clientUserConfig: {
    one_button_config: 3,
  },
}
const userReducer = produce((state, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SET_USER_INFO: {
      state.userInfo = payload
      break
    }
    case types.SET_PREMIUM_ACCOUNT: {
      state.userInfo.premiumAccount = payload
      break
    }
    case types.SET_THEME: {
      if (themeMap[payload]) {
        window.localStorage.setItem(THEME_STORAGE_KEY, payload)
        state.theme = themeMap[payload]
      }
      break
    }
    case types.SET_APP_LANGUAGE: {
      if (payload) {
        window.localStorage.setItem(LANGUAGE_STORAGE_KEY, payload)
        document.documentElement.setAttribute('lang', payload)
        state.language = payload
      }
      break
    }
    case types.CLIENT_USER_CONFIG: {
      state.clientUserConfig = payload
      break
    }
    default: {
    }
  }
}, INIT_STATE)

export default userReducer
