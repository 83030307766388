// import axios from './fetch'

window.flexible = window.flexible || { rem: 101 }

export default class Utils {
  static isDev() {
    return process.env.NODE_ENV === 'development'
  }
  static getParamByName(param) {
    const queryParams = new URLSearchParams(window.location.search)
    return queryParams.get(param)
  }
  static getHost() {
    return window.__HOST_CONFIG__.baseHost
  }
  static getCloudHost() {
    // test
    // return 'https://staging.now.gg'
    // production
    return window.__HOST_CONFIG__.cloudHost
  }
  static getLocalServerHost() {
    return window.__HOST_CONFIG__.xHost
  }
  static getInfluencerHost() {
    return window.__HOST_CONFIG__.ssoHost
  }
  static getParams() {
    const queryParams = new URLSearchParams(window.location.search)
    const params = queryParams.entries()
    let urlParams = ''
    for (let key of params) {
      urlParams += `&${key[0]}=${key[1]}`
    }
    return urlParams.substring(1, urlParams.length)
  }
  static handleReloadPage = () => {
    window.location.reload()
  }

  // 获取指定大小在当前屏幕下的大小
  static getResponseSise = (size) => {
    return (size * window.flexible.rem) / 126
  }

  // 根据大小px获取rem
  static getRemSizeByResponseSise = (size) => {
    return size / window.flexible.rem
  }

  // 获取设计稿大小下对应的rem大小
  static getRemSize = (size) => {
    return size / 126
  }

  // useful
  static getVmListPopupCoordinate = (event, vmListLength) => {
    const offsetWidth = document.querySelector('body').offsetWidth
    const offsetHeight = document.querySelector('body').offsetHeight
    const sizeTen = Utils.getResponseSise(10)
    const coordinate = { clientX: event.clientX - sizeTen, clientY: event.clientY - sizeTen }
    if (offsetWidth - coordinate.clientX < 22 * sizeTen) {
      coordinate.clientX = coordinate.clientX - 18 * sizeTen
    }
    const popupHeight = (Math.min(vmListLength, 5) + 1) * 3 * sizeTen
    if (offsetHeight - coordinate.clientY < popupHeight) {
      coordinate.clientY = coordinate.clientY - popupHeight + 2 * sizeTen
    }
    // return coordinate
    return {
      clientY: coordinate.clientY / window.flexible.rem + 'rem',
      clientX: coordinate.clientX / window.flexible.rem + 'rem',
    }
  }
}
