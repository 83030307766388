import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Heading from '../common/Heading/Heading'
import AspectImage from '../common/Image/AspectImage'
import { onGameClickAction } from '@/actions/commonActions'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import ActionButton from '@/components/actionButton/Index'
import { useHistory } from 'react-router-dom'
import style from './advertisementSection.module.scss'
import { pushAppStack } from '@/reducers/appdetail/actions'
import stats, { getLocationMapStatInfo, getPathNameLocationMap } from '@/tool/stats'
import { qwebApi } from '@/apis/qwebApi'
import { getButtonText } from '@/tool'
import { useIntl } from 'react-intl'
const AdvertisementSection = ({
  app,
  titleId,
  showInstalledState,
  imgPlaceholderClass,
  bannerLocation,
}) => {
  const history = useHistory()
  const installedApps = useSelector((state) => state.app.installedApps)
  const countryCode = useSelector((state) => state.system.countryCode)
  const oneButtonConfig = useSelector((state) => state.user.clientUserConfig.one_button_config)
  const clientMode = useSelector((state) => state.system.mode)
  const csApps = useSelector((state) => state.app.csApps)
  const isInstalled = installedApps.includes(app.package_name)
  const isCloudMode = clientMode === 'cloud'
  const dispatch = useDispatch()
  const intl = useIntl()

  // 点击游戏按钮
  const handleOnClick = useCallback(
    ({ app, evt, gameType, actionCode }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        dispatch,
        gameType,
        bannerLocation,
        actionCode,
      })
    },
    [dispatch, bannerLocation, isInstalled]
  )
  // 是否显示查看详情按钮
  const hasDetailAction = useMemo(() => {
    if (!app.isAppDetailAccessable || countryCode === 'JP') {
      return false
    }
    return true
  }, [app, countryCode])
  // 跳转至详情页
  // const onGotoDetail = useCallback(() => {
  //   history.push({
  //     pathname: `/app/${app.package_name}`,
  //   })
  //   dispatch(pushAppStack(app))
  // }, [app, history, dispatch])

  // csInon点击
  const onCsIconClick = useCallback(
    (evt) => {
      evt.stopPropagation()
      const csStatsInfo = getLocationMapStatInfo(bannerLocation)
      const appPage = getPathNameLocationMap(csStatsInfo)
      qwebApi({
        event: 'switchToCSGameFromSearch',
        data: {
          from: appPage,
          ...app,
          cs_stats_info: { ...csStatsInfo, appPage },
        },
      })
      stats(stats.TABLE_TYPE.ACTIVE_USERS, stats.STATS_POINT.BSX_CS_BUTTON_CLICKED, {
        ...csStatsInfo,
        app_pkg: app.package_name,
      })
    },
    [app, bannerLocation]
  )

  // 是否支持cs
  const isCsApp = useMemo(() => {
    return csApps.findIndex((a) => a.packageName === app.package_name) > -1
  }, [csApps, app])

  const localPerference = useSelector((state) => state.app.localPerference[app.package_name])

  const { gameType, actionCode } = getButtonText(intl, {
    isInstalled,
    app,
    platforms: localPerference,
    isCloudMode,
    oneButtonConfig,
  })

  let _actionCode = actionCode
  let _gameType = gameType
  if (app.cloud_supported && oneButtonConfig !== 0) {
    _actionCode = 'viewDetails'
    _gameType = ''
  }

  return (
    <div
      tabIndex="0"
      className={classNames(['responsive-container', style.container, imgPlaceholderClass])}
      style={{
        backgroundImage: `linear-gradient(109.53deg, rgba(0, 0, 0,0.8) 0%, rgba(0, 0, 0, 0) 100%),url(${app.banner_url})`,
      }}
    >
      <div className={style.containerInner}>
        {titleId && (
          <Heading
            className={classNames(['heading', 'sectionHeading', style.adHeading])}
            titleId={titleId}
          />
        )}
        {/* 小图片 */}
        <div className={classNames(['flex', style.adSectionContent])}>
          {/* 左边 */}
          <AspectImage className={style.smallIconContainer} src={app.icon_url}>
            {/* 已安装 */}
            {showInstalledState && isInstalled && (
              <SvgIcon size={3} icon="installed" className={style.installed} />
            )}
            {isCsApp && (
              <div className={style.rightTopContainer}>
                <div className={style.csIcon} onClick={onCsIconClick}>
                  <SvgIcon size={1} icon="cs-icon" />
                </div>
              </div>
            )}
            {hasDetailAction && (
              <div
                className={style.detailClickPanel}
                onClick={(evt) =>
                  handleOnClick({ app, evt, gameType: _gameType, actionCode: _actionCode })
                }
              >
                <SvgIcon size={1.5} icon="zoomLarge" className={style.detailIcon} />
              </div>
            )}
          </AspectImage>
          {/* 右边 */}
          <div className={style.appDetails}>
            {!app.isOpenInBrowser && (
              <p className={style.availableText}>
                <FormattedMessage id="availableNow"></FormattedMessage>
              </p>
            )}
            <p className={style.appName}>{app.game_name}</p>
            {app.platform_name && <p className={style.platformName}>{app.platform_name}</p>}
            {/* 操作按钮 */}
            <ActionButton
              className={style.bannerAction}
              app={app}
              isInstalled={isInstalled}
              handleOnClick={handleOnClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AdvertisementSection)
