import React, { useCallback, useEffect, useRef, useState, useLayoutEffect } from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { CSSTransition } from 'react-transition-group'
import { useDebounceFn } from 'ahooks'

import useScrollBarActive from '@/hook/useScrollBarActive'
import useScrollMemory from '@/hook/useScrollMemory'

import './index.scss'
// 记录页面的状态
const bannerStatus = 'BANNER_STATUS'
const FullScreenScroll = ({
  scrollKey = 'scroll',
  renderHeader,
  renderBody,
  showPageDownBtn = true,
  className,
  scrollDownText = 'moreGame',
}) => {
  const fullScreenContainerRef = useRef(null)
  const sectionHeaderRef = useRef(null)
  const sectionBodyRef = useRef(null)
  const scrollableTimer = useRef(true)
  const isHeaderBottomRef = useRef(0)
  const isBodyTopRef = useRef(0)
  const [pageMode, setPageMode] = useState(
    sessionStorage.getItem(`${bannerStatus}_${scrollKey}`) || (renderHeader ? 'header' : 'body')
  )

  useScrollMemory(sectionBodyRef, { key: scrollKey })
  // 记录页面的状态
  useEffect(() => {
    if (pageMode) {
      sessionStorage.setItem(`${bannerStatus}_${scrollKey}`, pageMode)
    }
  }, [pageMode, scrollKey])
  // 点击更多事件
  const onToggleMode = useCallback((direct = 'up') => {
    const mode = direct === 'up' ? 'header' : 'body'
    setPageMode(mode)
  }, [])

  const isHeaderMode = pageMode === 'header'

  // 控制滾動期間不可再次觸發wheel事件
  useEffect(() => {
    scrollableTimer.current = false
    const timer = setTimeout(() => {
      scrollableTimer.current = true
    }, 500)
    return () => clearTimeout(timer)
  }, [pageMode])

  // 鼠标滚动
  const onWheel = useCallback(
    (e) => {
      e.preventDefault()
      const { deltaY } = e
      // 向下滚
      if (deltaY > 0 && isHeaderMode && scrollableTimer.current) {
        const bannerDom = sectionHeaderRef.current
        const isReachBottom = bannerDom.scrollTop + window.innerHeight >= bannerDom.clientHeight
        if (isReachBottom) {
          isHeaderBottomRef.current++
        }
        if (isHeaderBottomRef.current >= 1) {
          isHeaderBottomRef.current = 0
          setPageMode('body')
        }
      } else {
        isHeaderBottomRef.current = 0
      }
      // 向上滚
      if (deltaY < 0 && !isHeaderMode && scrollableTimer.current) {
        const listDom = sectionBodyRef.current
        const isReachTop =
          listDom.scrollTop + fullScreenContainerRef.current.clientHeight <= listDom.clientHeight
        if (isReachTop) {
          isBodyTopRef.current++
        }
        if (isBodyTopRef.current >= 1) {
          isBodyTopRef.current = 0
          setPageMode('header')
        }
      } else {
        isBodyTopRef.current = 0
      }
    },
    [isHeaderMode]
  )
  const onHandlerWheel = useDebounceFn(onWheel, { wait: 50 })
  useScrollBarActive(sectionBodyRef)
  // 注册window滚动事件
  useEffect(() => {
    renderHeader && window.addEventListener('wheel', onHandlerWheel.run, false)
    return () => {
      renderHeader && window.removeEventListener('wheel', onHandlerWheel.run)
    }
  }, [onHandlerWheel.run, renderHeader])

  // 阻止鼠标中键点击事件
  useEffect(() => {
    const container = sectionBodyRef.current
    function onMiddleClick(e) {
      if (e.button === 1) {
        e.preventDefault()
      }
    }
    container && container.addEventListener('mousedown', onMiddleClick, false)
    return () => {
      container && container.removeEventListener('mousedown', onMiddleClick)
    }
  }, [])
  return (
    <main className={classNames(['fullScreenScroll', className])} ref={fullScreenContainerRef}>
      {renderHeader && (
        <CSSTransition in={isHeaderMode} classNames="fade" timeout={500} appear>
          <section
            className={classNames([
              'fullScreenScrollSection',
              'fullScreenScrollHeader',
              { isActive: isHeaderMode },
            ])}
            ref={sectionHeaderRef}
          >
            {renderHeader({ onToggleMode, pageMode, isActive: isHeaderMode })}
          </section>
        </CSSTransition>
      )}
      <CSSTransition in={!isHeaderMode} classNames="fade" timeout={150} appear>
        <section
          className={classNames([
            'fullScreenScrollSection',
            'fullScreenScrollBody',
            { isActive: !isHeaderMode },
          ])}
          ref={sectionBodyRef}
        >
          {renderBody({ onToggleMode, pageMode, isActive: !isHeaderMode })}
        </section>
      </CSSTransition>
      {isHeaderMode && showPageDownBtn ? (
        <CSSTransition in={isHeaderMode} classNames="fade" timeout={500} appear>
          <div className="pullDownContainer">
            <span className="downPageBtn" onClick={() => onToggleMode('down')}>
              <FormattedMessage id={scrollDownText}></FormattedMessage>
            </span>
          </div>
        </CSSTransition>
      ) : null}
    </main>
  )
}

export default FullScreenScroll
