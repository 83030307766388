import * as types from './constant'

import { getIpCountry } from '@/apis/apps'

// 设置是否显示无网络状态页面
export const setAppOfflinePage = (status = true) => {
  return {
    type: types.SHOW_APP_OFFLINE_PAGE,
    payload: status,
  }
}

// 设置网络状态
export const setIsOffline = (isOnline = true) => {
  return {
    type: types.SET_IS_ONLINE,
    payload: isOnline,
  }
}

// 设置vmpopup位置
export const setVmListData = (vmInfo) => {
  return {
    type: types.SET_VM_POPUP,
    payload: vmInfo,
  }
}

// 切换loader
export const switchLoader = (isLoader = true) => {
  let timer = null
  return (dispatch) => {
    clearInterval(timer)
    timer = setTimeout(() => {
      dispatch({
        type: types.SWITCH_LOADER,
        payload: isLoader,
      })
    }, 300)
  }
}

// 设置是否boot完成
export const setIsBootComplete = (isBootComplete) => {
  return {
    type: types.IS_BOOT_COMPLETE,
    payload: isBootComplete,
  }
}

// 设置区域码
export const setLocalCode = (localCode) => {
  let localCodeModify = localCode
  if (localCode === 'pt') {
    localCodeModify = 'pt-br'
  }
  if (localCode === 'cht') {
    localCodeModify = 'tw'
  }
  return {
    type: types.SET_APP_LOCAL_CODE,
    payload: localCodeModify,
  }
}

// 获取系统国家码
export const fetchCountryCode = () => {
  return async (dispatch, getState) => {
    let countryCode = getState().system.countryCode
    // if (!countryCode) {
    // dispatch(switchLoader())
    try {
      const _result = await getIpCountry()
      countryCode = _result.country
    } catch (error) {
      // countryCode = getState().system.localeCode === 'ja' ? 'JP' : ''
    }
    // dispatch(switchLoader(false))
    dispatch({
      type: types.SET_APP_COUNTRY_CODE,
      payload: countryCode,
    })
    // }
    return countryCode
  }
}

// 设置页面location
export const setPageLocation = (location) => {
  return {
    type: types.SET_APP_LOCATION_PATH,
    payload: location,
  }
}

// 设置客户端config
export const setClientConfig = (config) => {
  return {
    type: types.SET_APP_CLIENT_CONFIG,
    payload: config,
  }
}

// 设置客户端模式mode
export const setClientMode = (mode) => {
  return {
    type: types.SET_APP_CLIENT_MODE,
    payload: mode,
  }
}
