import React, { useContext, useState, useEffect, useMemo } from 'react'
import { useDebounceFn } from 'ahooks'

const ViewportContext = React.createContext({})

export const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const onWindowResize = useDebounceFn(
    () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    },
    { wait: 0 }
  )

  useEffect(() => {
    window.addEventListener('resize', onWindowResize.run, false)
    return () => window.removeEventListener('resize', onWindowResize.run)
  }, [onWindowResize])

  return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>
}
const defaultPoints = {
  sx: 480,
  sm: 768,
  md: 908,
  lg: 1300,
  llg: 1600,
}
const Hook = (breakpoints = defaultPoints) => {
  const { width, height } = useContext(ViewportContext)
  const point = useMemo(() => {
    if (width >= breakpoints.llg) {
      return 'llg'
    }
    if (width >= breakpoints.lg) {
      return 'lg'
    }
    if (width >= breakpoints.md) {
      return 'md'
    }
    if (width >= breakpoints.sm) {
      return 'sm'
    }
    if (width <= breakpoints.sx) {
      return 'sx'
    }
  }, [width, breakpoints])
  return { width, height, point }
}

export default Hook
