import React, { useMemo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@/components/common/Button/Button'
import SubHeadingStrip from '@/components/common/SubHeadingStrip/Index'
import GameList from '@/components/GameList/Index'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import { setTopicApps } from '@/reducers/app/actions'
import { switchLoader } from '@/reducers/system/actions'
import { STAT_APPS_PAGE_TYPEFOR_TOPIC_FROM_ANDROIDGAMES } from '@/tool/constant'
import { getTopicInfo } from '@/apis/apps'
import style from './index.module.scss'
const ByTopicApps = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const urlParams = new URLSearchParams(location.search)
  const topicId = urlParams.get('topicId')
  const topics = useSelector((state) => state.app.topics)
  // 根据游戏类型获取游戏（云游戏、安卓游戏...）
  const topicApps = useSelector((state) => state.app.topicAppsMap[topicId]) || []
  const activeTopic = useMemo(() => {
    return topics.find((topic) => topic.id === topicId)
  }, [topics, topicId])

  const { refetch, isSuccess, isFetching, isFetched } = useQuery(
    ['getTopicInfo', topicId],
    () => getTopicInfo({ topic_id: topicId }),
    {
      enabled: !!topicId && topicApps.length === 0,
      onSuccess: (data) => {
        dispatch(setTopicApps({ data: data.result || {}, topicId }))
      },
    }
  )

  // loading事件
  useEffect(() => {
    dispatch(switchLoader(isFetching))
  }, [isFetching, dispatch])

  return (
    <div className={style.container}>
      <GameList
        gameItemProps={{
          imgPlaceholderClass: 'ratio-1-1',
          showPlayBtn: true,
          showCloudBtn: true,
          showInstalledState: true,
          showCsIcon: true,
          bannerLocation: STAT_APPS_PAGE_TYPEFOR_TOPIC_FROM_ANDROIDGAMES,
        }}
        list={topicApps}
        contentInnerClass={['breakScreen', 'marginBottom30']}
        columns={6}
        customTitleRender={() => {
          return (
            <SubHeadingStrip
              title={
                <FormattedMessage
                  id="subHeadingCategory"
                  values={{ category: activeTopic?.topic_name }}
                />
              }
              count={topicApps.length}
              className="breakScreen"
            />
          )
        }}
        prefix={(list) => {
          if (list.length === 0 && isFetched) {
            return (
              <CommonHelpTip
                textTitle={isSuccess ? 'noFoundBySearch' : 'noInternetConnect'}
                imgSrc={isSuccess ? 'noResults' : 'noNetwork'}
                localSvg
                extra={() => (
                  <Button type="primary" onClick={refetch} className={style.refreshButton}>
                    <FormattedMessage id="refresh" />
                  </Button>
                )}
              />
            )
          }
        }}
      />
    </div>
  )
}

export default React.memo(ByTopicApps)
