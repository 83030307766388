import { cloudGanmeAppsTransformer } from '@/transformers'
import * as types from './constant'
// import { updatePlayListCloudInfo } from '../playerlist/actions'
import { getCloudGameApps } from '@/apis/apps'
import { switchLoader } from '../system/actions'

// 获取云游戏列表
export const fetchCloudGameApps = ({ showLoading, locale, showDisconnect }) => {
  return async (dispatch, getState) => {
    const localeCode = locale || getState().system.localeCode
    if (showLoading) {
      dispatch(switchLoader())
    }
    try {
      const _result = await getCloudGameApps({ showDisconnect, locale: localeCode })
      dispatch({
        type: types.FETCH_CLOUDGAMES_APPS,
        payload: cloudGanmeAppsTransformer(_result),
      })

      // 通知playlist更新云游戏信息
      // dispatch(updatePlayListCloudInfo())
    } catch (error) {
      dispatch({
        type: types.FETCH_CLOUDGAMES_APPS,
        payload: {
          cloudGames: [],
          isFetchSuccess: false,
        },
      })
    }
    if (showLoading) {
      dispatch(switchLoader(false))
    }
  }
}
