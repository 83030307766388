import { useState, useEffect, useCallback } from 'react'
import { on, off, qwebApi } from '@/apis/qwebApi'
const Hook = () => {
  const [localPerference, setLocalPerference] = useState({})
  useEffect(() => {
    qwebApi({ event: 'getLocalPerference', callbackEvent: 'updateLocalPerference' })
  }, [])
  // 更新回调
  const onUpdateLocalPerference = useCallback((jsonObject) => {
    try {
      if (jsonObject) {
        setLocalPerference(jsonObject)
      }
    } catch (error) {
      setLocalPerference({})
    }
  }, [])
  // 监听最近游戏变化
  useEffect(() => {
    on('updateLocalPerference', onUpdateLocalPerference)
    return () => {
      off('updateLocalPerference', onUpdateLocalPerference)
    }
  }, [onUpdateLocalPerference])
  return localPerference
}

export default Hook
