// banner_location constant

// 搜索页
export const STAT_SEARCH_PAGE_RESULT = 'STAT_SEARCH_PAGE_RESULT'

// 查看所有apps
export const STAT_APPS_PAGE = 'STAT_APPS_PAGE'
export const STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_GAMECENTERS =
  'STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_GAMECENTERS'
export const STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_HOME =
  'STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_HOME'
export const STAT_APPS_PAGE_TYPEFOR_JUNGAMES_FROM_HOME = 'STAT_APPS_PAGE_TYPEFOR_JUNGAMES_FROM_HOME'
export const STAT_APPS_PAGE_TYPEFOR_MUSTPLAYGAMES_FROM_GAMECENTER =
  'STAT_APPS_PAGE_TYPEFOR_MUSTPLAYGAMES_FROM_GAMECENTER'
export const STAT_APPS_PAGE_TYPEFOR_NEWANDTRENDING_FROM_GAMECENTER =
  'STAT_APPS_PAGE_TYPEFOR_NEWANDTRENDING_FROM_GAMECENTER'
export const STAT_APPS_PAGE_TYPEFOR_TOPIC_FROM_ANDROIDGAMES =
  'STAT_APPS_PAGE_TYPEFOR_TOPIC_FROM_ANDROIDGAMES'
export const STAT_APPS_PAGE_MORE_STRATEGY_GAMES_FROM_APPDETAIL =
  'STAT_APPS_PAGE_MORE_STRATEGY_GAMES_FROM_APPDETAIL'
export const STAT_APPS_PAGE_SIMILAR_GAMES_FROM_APPDETAIL =
  'STAT_APPS_PAGE_SIMILAR_GAMES_FROM_APPDETAIL'

// cloud页面
export const STAT_CLOUDGAMES_PAGE_TOP = 'STAT_CLOUDGAMES_PAGE_TOP'
export const STAT_CLOUDGAMES_PAGE_MORE = 'STAT_CLOUDGAMES_PAGE_MORE'

// gameCenter页面
export const STAT_ANDROIDGAMES_PAGE_CAROUSEL = 'STAT_ANDROIDGAMES_PAGE_CAROUSEL'
export const STAT_GAMECENTER_PAGE_RECOMMENDED = 'STAT_GAMECENTER_PAGE_RECOMMENDED'
export const STAT_GAMECENTER_PAGE_FEATURE = 'STAT_GAMECENTER_PAGE_FEATURE'
export const STAT_GAMECENTER_PAGE_MUSTPLAYGAMES = 'STAT_GAMECENTER_PAGE_MUSTPLAYGAMES'
export const STAT_GAMECENTER_PAGE_NEWANDTRENDING = 'STAT_GAMECENTER_PAGE_NEWANDTRENDING'

// home页面
export const STAT_HOME_PAGE_BANNER = 'STAT_HOME_PAGE_BANNER'
export const STAT_HOME_PAGE_JUNGAMES = 'STAT_HOME_PAGE_JUNGAMES'
export const STAT_HOME_PAGE_ANDROIDGAMES = 'STAT_HOME_PAGE_ANDROIDGAMES'
export const STAT_HOME_PAGE_INFLUENCER_LIST = 'STAT_HOME_PAGE_INFLUENCER_LIST'

export const STAT_HOME_PAGE_TRENDINGGAMES = 'STAT_HOME_PAGE_TRENDINGGAMES'
export const STAT_HOME_PAGE_POPULARGAMES = 'STAT_HOME_PAGE_POPULARGAMES'

export const STAT_APPS_PAGE_TYPEFOR_TRENDINGGAMES_FROM_HOME =
  'STAT_APPS_PAGE_TYPEFOR_TRENDINGGAMES_FROM_HOME'
export const STAT_APPS_PAGE_TYPEFOR_POPULARGAMES_FROM_HOME =
  'STAT_APPS_PAGE_TYPEFOR_POPULARGAMES_FROM_HOME'

// appDetail页面
export const STAT_APP_DETAIL_PAGE_BANNER = 'STAT_APP_DETAIL_PAGE_BANNER'
export const STAT_APP_DETAIL_PAGE_SCREENSHOT_AND_VIDEO = 'STAT_APP_DETAIL_PAGE_SCREENSHOT_AND_VIDEO'
export const STAT_APP_DETAIL_PAGE_MORE_STRATEGY_GAMES = 'STAT_APP_DETAIL_PAGE_MORE_STRATEGY_GAMES'
export const STAT_APP_DETAIL_PAGE_SIMILAR_GAMES = 'STAT_APP_DETAIL_PAGE_SIMILAR_GAMES'
