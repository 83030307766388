import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import Button from '@/components/common/Button/Button'

// actions
import { setInstalledApps, setCsApps, setLocalPerference } from '@/reducers/app/actions'
import { setClientUserConfig, setUserInfo } from '@/reducers/user/actions'
import { setPageLocation, setIsOffline, setClientMode } from '@/reducers/system/actions'
import { pushAppStack } from '@/reducers/appdetail/actions'

import useInstalledApps from '@/hook/useInstalledApps'
import usePerference from '@/hook/usePerference'
import useUserToken from '@/hook/useUserToken'
import useClientUserConfig from '@/hook/useClientUserConfig'
import useCsGames from '@/hook/useCsGames'
import useGmQuery from '@/hook/useGmQueryRemote'
import useNetwork from '@/hook/useNetwork'
import useGotoDetailForClient from '@/hook/useGotoDetailForClient'
import useCloudMode from '@/hook/useCloudMode'

import { on, off, qwebApi } from '@/apis/qwebApi'
import { useRef } from 'react'

// 页面国际化映射
const PageIntlMap = {
  gameCenter: 'androidGames',
  home: 'home',
  cloudGame: 'cloudGame',
}
const PageIntlMapProxy = new Proxy(PageIntlMap, {
  get(obj, key) {
    return obj[key] || 'page'
  },
})

const Setup = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const pageLocation = useSelector((state) => state.system.location)
  const loader = useSelector((state) => state.system.loader)

  const pageLocationRef = useRef(pageLocation)
  useEffect(() => {
    pageLocationRef.current = pageLocation
  }, [pageLocation])
  // 搜索栏交互逻辑
  useGmQuery()

  // 全局loader
  useEffect(() => {
    if (loader) {
      qwebApi({ event: 'openClientLoading' })
    } else {
      qwebApi({ event: 'closeClientLoading' })
    }
  }, [loader])
  // 客户端跳转app detail
  const onOpenDetail = useCallback(
    (appDetail) => {
      if (appDetail) {
        // $$isClear是否清除appStack，默认清除；$$from来自于哪里，默认客户端searchBar
        const { $$isClear = true, $$from = 'searchBar', ...app } = appDetail
        dispatch(pushAppStack({ ...app, $$isClear }))
        if ($$from === 'searchBar') {
          // 手动将页面切换回home
          dispatch(setPageLocation('home'))
        }
        history.push({
          pathname: `/app/${appDetail.package_name}`,
          state: {
            from: $$from,
          },
        })
      }
    },
    [history, dispatch]
  )

  useGotoDetailForClient(onOpenDetail)

  // local perference
  const localPerference = usePerference()
  useEffect(() => {
    dispatch(setLocalPerference(localPerference))
  }, [localPerference, dispatch])

  // client配置信息
  const clientUserConfig = useClientUserConfig()
  useEffect(() => {
    dispatch(setClientUserConfig(clientUserConfig))
  }, [dispatch, clientUserConfig])

  // 用户信息
  const userToken = useUserToken()
  useEffect(() => {
    dispatch(setUserInfo(userToken))
  }, [userToken, dispatch])

  // 更新已安装游戏（卸载和安装）---只有包名称
  const installedApps = useInstalledApps()
  useEffect(() => {
    dispatch(setInstalledApps(installedApps))
  }, [installedApps, dispatch])

  // bsx模式
  const { mode } = useCloudMode()
  useEffect(() => {
    if (mode) {
      dispatch(setClientMode(mode))
      window.__CLIENT_CONFIG__.commonConfig.cloud_mode = mode === 'cloud'
      const redirectPath = `/${pageLocationRef.current}`
      if (window.location.pathname !== redirectPath) {
        qwebApi({ event: 'closeClientLoading' })
        // qwebApi({ event: 'changeBannerBackgroundImage' })
        history.push({
          pathname: redirectPath,
          search: 'animation=fade',
        })
      }
    }
  }, [mode, dispatch, history])

  // 更新cs games
  const csGames = useCsGames()
  useEffect(() => {
    dispatch(setCsApps(csGames))
  }, [csGames, dispatch])

  // 页面路由跳转(client触发)
  useEffect(() => {
    const onRouterTo = ({ path }) => {
      if (pageLocationRef.current === path) {
        return
      }
      dispatch(setPageLocation(path))
      qwebApi({ event: 'closeClientLoading' })
      // qwebApi({ event: 'changeBannerBackgroundImage' })
      history.push({
        pathname: `/${path}`,
        search: 'animation=fade',
      })
    }
    on('pageRouterTo', onRouterTo)
    return () => {
      off('pageRouterTo', onRouterTo)
    }
  }, [dispatch, history])

  // 网络波动
  const [online, prevOnline] = useNetwork()
  useEffect(() => {
    dispatch(setIsOffline(online))
  }, [online, dispatch])

  // 应用在线逻辑（是否显示断网提示页面）
  const showOfflinePage = useSelector((state) => state.status.showOfflinePage)
  useEffect(() => {
    if (showOfflinePage) {
      qwebApi({ event: 'changeBannerBackgroundImage' })
    }
  }, [showOfflinePage])

  if (online && !prevOnline) {
    setTimeout(() => {
      window.location.href = `/${pageLocation}`
    }, 5000)
  }
  if (showOfflinePage) {
    // 通知client刷新页面
    const onRefreshClick = () => {
      window.location.href = `/${pageLocation}`
    }
    return (
      <CommonHelpTip
        textTitle="noInternetConnect"
        textValue="offlineMessageBySearch"
        className="appNoNetworkContainer"
        imgSrc="noNetwork"
        localSvg={true}
        values={{
          page: <FormattedMessage id={PageIntlMapProxy[pageLocation]} />,
        }}
        extra={() => (
          <Button
            type="primary"
            className="margin-top-20 font16 minw200"
            onClick={onRefreshClick}
            titleId="refresh"
          />
        )}
      />
    )
  }
  return null
}

export default Setup
