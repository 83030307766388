import fetch from '../fetch'

/**
 * 获取App详情
 * @param {Object} packageName包名
 * @returns Promise
 */
export const getAppDetail = ({ packageName }) => {
  const url = `${window.__HOST_CONFIG__.detailHost}/${packageName}?token=13c7bf80-5917-4343-8a75-13582c438425`
  return fetch({
    method: 'get',
    url: url,
    params: {
      packageName,
      ...window.__CLIENT_CONFIG__.commonConfig,
      ...window.__CLIENT_CONFIG__.systemConfig,
    },
    noValidate: true,
  })
}
