import React from 'react'

import FullScreenScroll from '@/components/FullScreenScroll/Index'

import Banner from './Banner'
import List from './List'
import './index.scss'

const Home = () => {
  return (
    <FullScreenScroll
      scrollKey="home"
      className="homeContainer"
      renderHeader={({ isActive }) => {
        return <Banner isActive={isActive} />
      }}
      renderBody={({ isActive }) => {
        return <List isActive={isActive} />
      }}
    />
  )
}

export default React.memo(Home)
