import produce from 'immer'
import * as types from './constant'

import { isQtClient } from '@/tool/qwebchannel/utils'

import { qwebApi } from '@/apis/qwebApi'

// 国家码
export const COUNTRY_CODE_STORAGE_KEY = 'COUNTRY_CODE_STORAGE_KEY'

export const LAST_APP_LOCATION = 'LAST_APP_LOCATION'

// 客户端模式存储key
export const CLIENT_MODE_KEY = 'CLIENT_MODE_KEY'

const INIT_STATE = {
  showOfflinePage: false,
  isOnline: true,
  vmPopup: {
    vmList: [],
    vmOptionsInfo: { coordinate: { clientX: 0, clientY: 0 } },
    gamePlayInfo: {},
  },
  loader: false,
  isBootComplete: false,
  countryCode: window.localStorage.getItem(COUNTRY_CODE_STORAGE_KEY) || '',
  localeCode: isQtClient ? '' : 'en',
  location: window.location.pathname.slice(1) || 'home',
  clientConfig: {},
  mode: window.localStorage.getItem(CLIENT_MODE_KEY) || 'hybrid',
}
export default produce((state, { type, payload }) => {
  switch (type) {
    case types.SHOW_APP_OFFLINE_PAGE:
      state.showOfflinePage = payload
      break
    case types.SET_IS_ONLINE:
      state.isOnline = payload
      break
    case types.SET_VM_POPUP:
      state.vmPopup = payload
      break
    case types.SWITCH_LOADER: {
      state.loader = payload
      break
    }
    case types.IS_BOOT_COMPLETE: {
      state.isBootComplete = payload
      break
    }
    case types.SET_APP_COUNTRY_CODE: {
      if (payload) {
        window.localStorage.setItem(COUNTRY_CODE_STORAGE_KEY, payload)
      }
      qwebApi({ event: 'syncCountryCode', data: { countryCode: payload } })
      state.countryCode = payload
      break
    }
    case types.SET_APP_LOCAL_CODE: {
      state.localeCode = payload
      break
    }
    case types.SET_APP_LOCATION_PATH: {
      state.location = payload
      break
    }
    case types.SET_APP_CLIENT_CONFIG: {
      state.clientConfig = payload
      break
    }
    case types.SET_APP_CLIENT_MODE: {
      if (payload) {
        window.localStorage.setItem(CLIENT_MODE_KEY, payload)
      }
      state.mode = payload
      break
    }
    default: {
    }
  }
}, INIT_STATE)
