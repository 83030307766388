import { emit, qwebApi } from '@/apis/qwebApi'
// import { getLocationMapStatInfo, getPathNameLocationMap } from '@/tool/stats'
import store from '@/store'
import stats, { TABLE_TYPE, STATS_POINT } from '@/tool/stats'

// 游戏类型映射
const gameTypeMap = {
  simulator: 'Android_Game',
  local: 'Android_Game',
  cloud: 'Cloud_Game',
  steam: 'Steam_Game',
}

const getEventType = (gameType, isInstalled) => {
  if (gameType === 'simulator') {
    if (isInstalled) {
      return 'play_on_android'
    } else {
      return 'install_on_android'
    }
  }
  if (gameType === 'cloud') {
    return 'play_on_cloud'
  }
  if (gameType === 'steam') {
    return 'play_on_steam'
  }
}

// 获取app点击数据
export const getAppClickStats = ({ app, bannerLocation, isInstalled, gameType }) => {
  return {
    app_pkg: app.package_name,
    banner_location: bannerLocation,
    event_type: getEventType(gameType, isInstalled),
    game_type: gameTypeMap[gameType],
  }
}

// 游戏点击逻辑
export const onGameClickAction = async ({
  app,
  isInstalled,
  evt,
  gameType = 'simulator',
  bannerLocation,
  actionCode,
}) => {
  evt.preventDefault()
  evt.stopPropagation()
  // bsx打点专用
  const statsData = getAppClickStats({ app, isInstalled, bannerLocation, gameType })

  // const csStatsInfo = getLocationMapStatInfo(bannerLocation)
  // const appPage = getPathNameLocationMap(csStatsInfo)
  const isAppDetailAccessable =
    app.isAppDetailAccessable && store.getState().system.countryCode !== 'JP'

  // 打开游戏必要信息
  const gamePlayInfo = {
    ...app,
    icon_url: app.icon_url,
    package_name: app.package_name,
    cloud_app_url: app.cloud_app_url,
    cloud_supported: app.cloud_supported,
    category: app.category,
    game_name: app.game_name,
    action: app.action,
    action_value: app.action_value,
    apk_url: app.apk_url,
    is_nowgg_login_required: app.is_nowgg_login_required,
    game_play_preference: app.game_play_preference,
    // 暂时无用
    cloud_type: app.cloud_supported
      ? new URL(app.cloud_app_url).pathname.startsWith('/lp/')
        ? 'lp'
        : 'adnet'
      : null,
    // cs打点专用
    // cs_stats_info: { ...csStatsInfo, appPage },
    isAppDetailAccessable,
  }
  // 当actionCode为'playOnCloud','installInAppPlayer','installViaGooglePlay'时进入游戏详情页并打点stats
  // viewDetails表示由点击详情页进入
  if (
    ['playOnCloud', 'installInAppPlayer', 'installViaGooglePlay', 'viewDetails'].includes(
      actionCode
    ) &&
    isAppDetailAccessable
  ) {
    emit('openDetailPage', { ...app, $$isClear: false, $$from: statsData.banner_location })
    stats(TABLE_TYPE.LAUNCHER_APP_CLICK, false, {
      ...gamePlayInfo,
      ...statsData,
      actionCode,
      event_type: STATS_POINT.BSX_JUMP_TO_DETAIL_PAGE,
    })
    return
  }
  // gameType:simulator模拟器游戏，cloud云游戏，steam PC游戏
  if (gameType === 'cloud') {
    qwebApi({
      event: 'openCloudGame',
      data: gamePlayInfo,
      ga: statsData,
    })
  } else if (gameType === 'steam') {
    qwebApi({
      event: 'openStreamGame',
      data: gamePlayInfo,
      ga: statsData,
    })
  } else if (gameType === 'simulator') {
    qwebApi({
      event: 'installOrPlayAppforVm',
      data: gamePlayInfo,
      ga: statsData,
    })
  }
}
