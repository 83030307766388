import classNames from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import svgLoading from '@/images/loading.svg'

const Button = ({
  className,
  styleObj,
  title,
  titleId,
  tabIndex,
  renderLabel,
  isLoading = false,
  disabled = false,
  size = 'default',
  type,
  children,
  mode = 'normal',
  tag = 'button',
  ...events
}) => {
  let labelJsx = null
  if (isLoading) {
    labelJsx = <img src={svgLoading} alt="loading" className="loadingButtonImg" />
  } else {
    labelJsx = children ? (
      children
    ) : titleId ? (
      <FormattedMessage id={titleId} />
    ) : (
      <span>{title}</span>
    )
  }
  if (renderLabel) {
    labelJsx = renderLabel()
  }
  const buttonTypeClass = type ? `${type}Button` : ''
  const Tag = tag
  return (
    <Tag
      tabIndex={tabIndex}
      className={classNames([
        'button',
        `${size}Button`,
        `${mode}Button`,
        buttonTypeClass,
        className,
        { isLoading: isLoading },
        { disabled: disabled },
      ])}
      style={styleObj}
      {...events}
      onClick={(evt) => {
        if (isLoading || disabled) {
          return
        }
        events.onClick && events.onClick(evt)
      }}
    >
      {labelJsx}
    </Tag>
  )
}

export default Button
