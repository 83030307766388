// 获取version的值
export const getVersionValue = (version) => {
  const verCode = version.split('.').map(Number).reverse()
  return verCode.reduce((value, code, index) => {
    return value + code * Math.pow(10, index)
  }, 0)
}

export const compareVersionFn = (version, compareVersion) => {
  const versionCode = version.split('.').map(Number)
  const compareVersionCode = compareVersion.split('.').map(Number)
  const compareResult = []
  for (let index = 0; index < versionCode.length; index++) {
    compareResult.push(versionCode[index] > compareVersionCode[index])
  }
  return compareResult
}

export const compareVersionHigh = (version = '', compareVersion, index = false) => {
  const compareResult = compareVersionFn(version, compareVersion)
  if (index === false) {
    return compareResult.some(Boolean)
  }
  return compareResult[index]
}
