import React, { useMemo, useCallback, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { useHover } from 'ahooks'
import { useIntl } from 'react-intl'

import AspectImage from '../Image/AspectImage'
import { onGameClickAction } from '@/actions/commonActions'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import Button from '@/components/common/Button/Button'
import MarqueeText from '@/components/common/marqueeText/Index'

import { qwebApi } from '@/apis/qwebApi'
import { pushAppStack } from '@/reducers/appdetail/actions'
import { getButtonText } from '@/tool'

import style from './index.module.scss'
import stats, { getLocationMapStatInfo, getPathNameLocationMap } from '@/tool/stats'

const GameListItem = ({
  className,
  nameClass,
  categoryClass,
  onImageLoad,
  onImageError,
  imageDisplayStrategy,
  showInstalledState,
  showPlayBtn,
  showCloudBtn,
  showPcBtn,
  showTypeIcon,
  imgPlaceholderClass,
  isVideo,
  game,
  onClick,
  onHoverEnter,
  onRootClick = () => {},
  bannerLocation,
  imageUrlGet = false,
  hoverImageUrlGet = false,
  lazyRoot = null,
  lazyRootMargin,
  bootGameOnClick = true,
  selfLazyLoad,
  showCsIcon,
  hasHoverAction = false,
  mouseEnterDelay = 500,
  lazy = true,
}) => {
  const intl = useIntl()
  const hoverRef = useRef(null)
  const hoverTimerRef = useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const installedApps = useSelector((state) => state.app.installedApps)
  const countryCode = useSelector((state) => state.system.countryCode)
  const csApps = useSelector((state) => state.app.csApps)
  const oneButtonConfig = useSelector((state) => state.user.clientUserConfig.one_button_config)
  const localPerference = useSelector((state) => state.app.localPerference[game.package_name])
  const clientMode = useSelector((state) => state.system.mode)
  const isInstalled = installedApps.includes(game.package_name)
  const isCloudMode = clientMode === 'cloud'

  const isHovering = useHover(hoverRef, {
    onEnter: () => {
      if (hasHoverAction && onHoverEnter) {
        hoverTimerRef.current = setTimeout(() => onHoverEnter(game), mouseEnterDelay)
      }
    },
    onLeave: () => {
      if (hasHoverAction) {
        clearTimeout(hoverTimerRef.current)
      }
    },
  })

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimerRef.current)
    }
  }, [])

  // 是否支持cs
  const isCsApp = useMemo(() => {
    return showCsIcon && csApps.findIndex((app) => app.packageName === game.package_name) > -1
  }, [showCsIcon, csApps, game])

  // 点击项目
  const handleOnClick = useCallback(
    ({ evt, gameType, actionCode }) => {
      if (onClick) {
        onClick(game, evt)
      }
      if (bootGameOnClick) {
        onGameClickAction({
          app: game,
          isInstalled,
          evt,
          dispatch,
          gameType,
          bannerLocation,
          actionCode,
        })
      }
    },
    [game, isInstalled, bannerLocation, dispatch, onClick, bootGameOnClick]
  )
  // 获取图片策略值
  const imageDisplayStrategyValue = useMemo(() => {
    return typeof imageDisplayStrategy === 'function'
      ? imageDisplayStrategy(game)
      : imageDisplayStrategy
  }, [imageDisplayStrategy, game])

  // 获取图片url值
  const imageUrl = useMemo(() => {
    return (
      (imageUrlGet && imageUrlGet(game)) ||
      game.icon_url ||
      game.banner_url ||
      game.banner_url_v ||
      game.game_tile_url ||
      game.img_url
    )
  }, [game, imageUrlGet])

  // 获取hover图片url值
  const hoverImageUrl = useMemo(() => {
    return (hoverImageUrlGet && hoverImageUrlGet(game)) || imageUrl
  }, [game, hoverImageUrlGet, imageUrl])

  // 是否显示游戏按钮
  const hasItemBtn = showPlayBtn || showCloudBtn || showPcBtn

  // icon点击事件
  const onImageClick = useCallback(
    (evt) => {
      if (!hasItemBtn) {
        handleOnClick({ evt, gameType: game.cloud_supported ? 'cloud' : 'simulator' })
      }
    },
    [handleOnClick, hasItemBtn, game.cloud_supported]
  )

  // csInon点击
  const onCsIconClick = useCallback(
    (evt) => {
      evt.stopPropagation()
      const csStatsInfo = getLocationMapStatInfo(bannerLocation)
      const appPage = getPathNameLocationMap(csStatsInfo)
      qwebApi({
        event: 'switchToCSGameFromSearch',
        data: {
          from: appPage,
          ...game,
          cs_stats_info: { ...csStatsInfo, appPage },
        },
      })
      stats(stats.TABLE_TYPE.ACTIVE_USERS, stats.STATS_POINT.BSX_CS_BUTTON_CLICKED, {
        ...csStatsInfo,
        app_pkg: game.package_name,
      })
    },
    [game, bannerLocation]
  )

  // 是否显示查看详情按钮
  const hasDetailAction = useMemo(() => {
    if (!game.isAppDetailAccessable || countryCode === 'JP') {
      return false
    }
    return true
  }, [game, countryCode])

  // 跳转至详情页
  // const onGotoDetail = useCallback(() => {
  //   clearTimeout(hoverTimerRef.current)
  //   history.push({
  //     pathname: `/app/${game.package_name}`,
  //   })
  //   dispatch(pushAppStack(game))
  // }, [game, history, dispatch])

  // 图片策略展示
  const imageJSX = useMemo(() => {
    const realSrc = isVideo
      ? game.video_url
      : hasHoverAction && isHovering
      ? hoverImageUrl
      : imageUrl

    const buttonJSX = []
    if (oneButtonConfig === 3) {
      if (showCloudBtn && game.showCloudBtn) {
        const { actionText, actionCode } = getButtonText(intl, {
          app: game,
          platforms: 'cloud',
          isCloudMode,
          oneButtonConfig,
        })
        buttonJSX.push(
          <Button
            key="playOnCloudButton"
            className={classNames([style.itemImgButton, style.cloudButton])}
            onClick={(evt) => handleOnClick({ evt, gameType: 'cloud', actionCode })}
          >
            {isHovering ? <MarqueeText>{actionText}</MarqueeText> : actionText}
          </Button>
        )
      }
      if (showPlayBtn && game.showPlayBtn) {
        const { actionText, actionCode } = getButtonText(intl, {
          isInstalled,
          app: game,
          platforms: 'local',
          isCloudMode,
          oneButtonConfig,
        })
        buttonJSX.push(
          <Button
            key="playOnBsButton"
            className={classNames([style.itemImgButton, style.installButton])}
            onClick={(evt) => handleOnClick({ evt, gameType: 'simulator', actionCode })}
          >
            {isHovering ? <MarqueeText>{actionText}</MarqueeText> : actionText}
          </Button>
        )
      }
    } else {
      if (!game.showPcBtn) {
        const { actionText, actionCode } = getButtonText(intl, {
          isInstalled,
          app: game,
          platforms: localPerference,
          isCloudMode,
          oneButtonConfig,
        })
        buttonJSX.push(
          <Button
            key="installButton"
            className={classNames([style.itemImgButton, style.installButton])}
            onClick={(evt) => handleOnClick({ evt, gameType: 'simulator', actionCode })}
          >
            {isHovering ? <MarqueeText>{actionText}</MarqueeText> : actionText}
          </Button>
        )
      }
    }

    if (showPcBtn && game.showPcBtn) {
      buttonJSX.push(
        <Button
          key="checkPcGameButton"
          titleId="checkPcGame"
          className={classNames([style.itemImgButton, style.pcButton])}
          onClick={(evt) => handleOnClick({ evt, gameType: 'steam' })}
        />
      )
    }
    let detailClickJSX = null
    if (hasDetailAction) {
      const { gameType, actionCode } = getButtonText(intl, {
        isInstalled,
        app: game,
        platforms: localPerference,
        isCloudMode,
        oneButtonConfig,
      })
      let _actionCode = actionCode
      let _gameType = gameType
      if (game.cloud_supported && oneButtonConfig !== 0) {
        _actionCode = 'viewDetails'
        _gameType = ''
      }
      detailClickJSX = (
        <div
          className={style.detailClickPanel}
          onClick={(evt) => handleOnClick({ evt, gameType: _gameType, actionCode: _actionCode })}
        >
          <SvgIcon size={1.5} icon="zoomLarge" className={style.detailIcon} />
        </div>
      )
    }
    return (
      <div className={classNames([style.slideUp, style.overlayContainer])} ref={hoverRef}>
        {/* 图片自适应布局 */}
        <AspectImage
          aspectClass={
            typeof imgPlaceholderClass === 'function'
              ? imgPlaceholderClass(game)
              : imgPlaceholderClass
          }
          isVideo={isVideo}
          src={realSrc}
          selfLazyLoad={selfLazyLoad}
          lazy={lazy}
          root={lazyRoot}
          rootMargin={lazyRootMargin}
          videoUrl={game.video_url}
          onLoad={onImageLoad}
          onError={onImageError}
          imageDisplayStrategy={imageDisplayStrategyValue}
          alt={realSrc}
          className={style.transitionImage}
          onClick={onImageClick}
        >
          {/* 已安装 */}
          {showInstalledState && isInstalled && (
            <SvgIcon size={3} icon="installed" className={style.installed} />
          )}
          {/* 是否cs icon */}
          {isCsApp && (
            <div className={style.rightTopContainer}>
              <div className={style.csIcon} onClick={onCsIconClick}>
                <SvgIcon size={1} icon="cs-icon" />
              </div>
            </div>
          )}
          {/* 操作按钮-打开游戏或云 */}
          {hasItemBtn && (
            <div className={style.buttonOverlay}>
              <div className={style.buttonWrap}>
                {detailClickJSX}
                {buttonJSX}
              </div>
            </div>
          )}
        </AspectImage>
      </div>
    )
  }, [
    isCloudMode,
    isCsApp,
    onCsIconClick,
    onImageClick,
    showCloudBtn,
    showPlayBtn,
    showPcBtn,
    handleOnClick,
    showInstalledState,
    isInstalled,
    game,
    hasItemBtn,
    imgPlaceholderClass,
    selfLazyLoad,
    onImageLoad,
    onImageError,
    imageUrl,
    isVideo,
    lazyRoot,
    lazyRootMargin,
    imageDisplayStrategyValue,
    oneButtonConfig,
    hasHoverAction,
    hoverImageUrl,
    isHovering,
    lazy,
    intl,
    hasDetailAction,
    localPerference,
  ])
  return (
    <div
      className={classNames([style.container, className])}
      onClick={(evt) => onRootClick(game, evt)}
    >
      {imageJSX}
      {game.game_name && (
        <p className={classNames([nameClass, style.gameName])}>{game.game_name}</p>
      )}
      {(game.category || showTypeIcon) && (
        <div className={style.footerPanel}>
          <span className={classNames([categoryClass, style.category])}>{game.category}</span>
          {showTypeIcon && (
            <div className={style.gameType}>
              {game.showCloudBtn && (
                <div className={style.typeIcon}>
                  <SvgIcon size={1.5} icon="cloud" className={['c-white']} />
                </div>
              )}
              {game.showPcBtn && (
                <div className={style.typeIcon}>
                  <SvgIcon size={1.5} icon="pc" className={['c-white']} />
                </div>
              )}
              {game.showPlayBtn && (
                <div className={style.typeIcon}>
                  <SvgIcon size={1.5} icon="android" className={['c-white']} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default GameListItem
