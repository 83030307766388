import React, { useCallback, useMemo, useRef } from 'react'
import classNames from 'classnames'
import Rate from 'rc-rate'
import 'rc-rate/assets/index.css'
import { useMount, useUnmount } from 'ahooks'
import { useSelector } from 'react-redux'
import ActionButton from '@/components/actionButton/Index'
import AspectImage from '@/components/common/Image/AspectImage'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import style from './banner.module.scss'
import stats, { getLocationMapStatInfo, getPathNameLocationMap } from '@/tool/stats'
import { qwebApi } from '@/apis/qwebApi'
const Banner = ({
  className,
  bannerGame,
  appOverview,
  isInstalled,
  handleOnClick,
  bannerLocation,
  onVisibleChange,
  simpleMode = false,
}) => {
  const observerRef = useRef(null)
  const containerRef = useRef(null)
  const csApps = useSelector((state) => state.app.csApps)

  // 是否支持cs
  const isCsApp = useMemo(() => {
    return csApps.findIndex((a) => a.packageName === bannerGame?.package_name) > -1
  }, [csApps, bannerGame])

  // csInon点击
  const onCsIconClick = useCallback(
    (evt) => {
      evt.stopPropagation()
      const csStatsInfo = getLocationMapStatInfo(bannerLocation)
      const appPage = getPathNameLocationMap(csStatsInfo)
      qwebApi({
        event: 'switchToCSGameFromSearch',
        data: {
          from: appPage,
          ...bannerGame,
          cs_stats_info: { ...csStatsInfo, appPage },
        },
      })
      stats(stats.TABLE_TYPE.ACTIVE_USERS, stats.STATS_POINT.BSX_CS_BUTTON_CLICKED, {
        ...csStatsInfo,
        app_pkg: bannerGame?.package_name,
      })
    },
    [bannerGame, bannerLocation]
  )

  useMount(() => {
    if (containerRef.current) {
      observerRef.current = new IntersectionObserver((entried) => {
        try {
          onVisibleChange(entried[0].isIntersecting)
        } catch (error) {}
      })
      observerRef.current.observe(containerRef.current)
    }
  })

  useUnmount(() => {
    if (observerRef.current) {
      // observerRef.current.unobserve(containerRef.current)
      observerRef.current.disconnect()
    }
  })

  const describtionJSX = useMemo(() => {
    const jsxs = []
    if (appOverview.genre_slug) {
      jsxs.push(<span key="genreSlug">{appOverview.genre_slug}</span>)
    }
    if (appOverview.developer_name) {
      jsxs.push(<span key="developerName">{appOverview.developer_name}</span>)
    }
    if (jsxs.length === 2) {
      jsxs.splice(1, 0, <span key="split" className={style.split}></span>)
    }
    if (jsxs.length) {
      return <div className={style.describtion}>{jsxs}</div>
    } else {
      return null
    }
  }, [appOverview.genre_slug, appOverview.developer_name])

  return (
    <div className={classNames([style.container, className])} ref={containerRef}>
      <AspectImage className={style.left} src={appOverview.icon_url} alt="appPageBannerImage">
        {/* 已安装 */}
        {isInstalled && <SvgIcon size={3} icon="installed" className={['installed']} />}
        {/* cs icon */}
        {isCsApp && (
          <div className={style.csIcon} onClick={onCsIconClick}>
            <SvgIcon size={1} icon="cs-icon" />
          </div>
        )}
      </AspectImage>
      <div className={style.right}>
        <h2 className={classNames([style.title, { [style.doubleLineTitle]: simpleMode }])}>
          {appOverview.app_name}
        </h2>
        {describtionJSX}
        {appOverview.app_rating ? (
          <Rate
            disabled
            defaultValue={appOverview.app_rating}
            style={{ fontSize: 18 }}
            allowHalf
            allowClear={false}
          />
        ) : null}
        <ActionButton
          className={style.actionButton}
          app={bannerGame}
          isInstalled={isInstalled}
          type="viewall"
          handleOnClick={handleOnClick}
        />
      </div>
    </div>
  )
}

export default React.memo(Banner)
