import React, {
  useEffect,
  useMemo,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from 'react'

import { useMount } from 'ahooks'

import classnames from 'classnames'
// import { usePrevious } from 'ahooks'
// import useRandomItems from '@/hook/useRandomItems'
import useViewport from '@/hook/useViewport'
import useScrollBarActive from '@/hook/useScrollBarActive'

import Utils from '@/Utils'

import './index.scss'
const Responsive = (
  {
    list,
    className,
    children,
    wrap,
    // 是否一行显示，滚动
    wrapInOneLine = false,
    // moveType = 'scroll',
    // 一行显示数据个数计算函数
    numberOfItems = 7,
    gutter = ({ width }) => (Utils.getResponseSise(15) / width) * 100,
    // 显示几行（在wrap为false且wrapInOneLine为false时有效）
    lineNumber = 1,
    keyid = 'index',
    // 动画相关
    mainAniStyle = { transition: 'transform 0.5s ease-in-out' },
    inStyle,
    outStyle,
    inClass,
    outClass,
    listAppend,
    prefixAppend,
    onMount = () => {},
  },
  ref
) => {
  const containerRef = useRef(null)
  const containerChildRef = useRef([])
  const [anchorCursor, setAnchorCursor] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [direction, setDirection] = useState(0)
  const { width, point } = useViewport()
  // 真实lineNumber
  const lineNumberAdapt = useMemo(() => {
    if (wrap || wrapInOneLine) {
      return 1
    }
    return lineNumber
  }, [wrap, wrapInOneLine, lineNumber])
  // 真实diaplsy
  const display = useMemo(() => {
    // 只有在wrap为true时采用grid布局
    if (wrap) {
      return 'grid'
    }
    return 'flex'
  }, [wrap])
  // 动画移动类型
  const moveType = useMemo(() => {
    if (wrapInOneLine) {
      return 'translateX'
    }
    return 'scroll'
  }, [wrapInOneLine])
  // 一行显示数据个数
  const wrapSizeValue = useMemo(() => {
    return typeof numberOfItems === 'function'
      ? numberOfItems({ width, point })
      : Number(numberOfItems)
  }, [numberOfItems, width, point])
  // 显示的数据总个数
  const itemsLength = useMemo(() => {
    const dataSize = list.length
    if (wrap || wrapInOneLine) {
      return dataSize
    }
    return Math.min(wrapSizeValue * Math.max(lineNumberAdapt, 1), dataSize)
  }, [wrap, list, wrapSizeValue, lineNumberAdapt, wrapInOneLine])

  // 项目间的间距
  const gutterSize = useMemo(() => {
    return typeof gutter === 'function'
      ? gutter({ width, point })
      : Number((Utils.getResponseSise(gutter) / width) * 100)
  }, [gutter, width, point])
  // 获取items（useRandomItems提供随机获取items，暂时不用，考虑耗内存）
  // const { itemsToShow, getRoundItems, spliceItemsByCount } = useRandomItems({
  //   list,
  //   itemsLength,
  //   isPuzzle,
  // })
  const itemsToShow = useMemo(() => {
    return list.slice(0, itemsLength)
  }, [list, itemsLength])

  useEffect(() => {
    const containerDom = containerRef.current
    function onHandlerWheel(e) {
      if (containerDom.scrollTop > 0) {
        e.stopPropagation()
      }
    }
    containerDom && containerDom.addEventListener('wheel', onHandlerWheel)
    return () => {
      containerDom && containerDom.removeEventListener('wheel', onHandlerWheel)
    }
  }, [])
  // 是否为行的最后一个item
  const isLastItem = useCallback((index) => (index + 1) % wrapSizeValue === 0, [wrapSizeValue])

  // 每个项目大小
  const itemSize = useMemo(() => {
    return (100 - gutterSize * (wrapSizeValue - 1)) / wrapSizeValue
  }, [gutterSize, wrapSizeValue])

  const translateXLength = useMemo(() => {
    return (itemSize + gutterSize) * anchorCursor
  }, [itemSize, gutterSize, anchorCursor])

  // 主容器style
  const mainStyle = useMemo(() => {
    const style = {
      display,
      columnGap: `${gutterSize}%`,
      rowGap: `${Utils.getRemSize(40)}rem`,
      gridTemplateColumns: `repeat(${wrapSizeValue},1fr)`,
      gridTemplateRows: 'max-content',
    }
    if (itemsToShow.length === 0) {
      style.marginBottom = '0px'
    }
    if (lineNumberAdapt > 1) {
      style.flexWrap = 'wrap'
    }
    if (moveType === 'translateX') {
      style.overflowX = 'unset'
      style.transform = `translateX(-${translateXLength}%)`
      // style.transitionDuration = `${wrapSizeValue * 0.2}s`
    }
    if (mainAniStyle) {
      Object.keys(mainAniStyle).reduce((s, key) => {
        s[key] = mainAniStyle[key]
        return s
      }, style)
    }
    return style
  }, [
    display,
    gutterSize,
    wrapSizeValue,
    lineNumberAdapt,
    moveType,
    translateXLength,
    mainAniStyle,
    itemsToShow,
  ])

  // 子项目style
  const itemStyle = useMemo(() => {
    const style = {}
    if (display === 'flex') {
      style.flex = `${itemSize}% 0 0`
      style.width = `${itemSize}%`
    }
    return style
  }, [display, itemSize])

  // 判断上一页下一页状态
  const turnStatus = useMemo(() => {
    const status = {
      isFirst: false,
      isLast: false,
      isActiveFirst: false,
      isActiveLast: false,
      index: anchorCursor,
      activeIndex,
      item: itemsToShow[activeIndex],
      direction,
      hasPrev: translateXLength > 0,
      hasNext:
        activeIndex === 0
          ? true
          : itemSize * wrapSizeValue + gutterSize * (wrapSizeValue - 1) + translateXLength <
            itemsLength * itemSize + gutterSize * (itemsLength - 1),
    }
    // console.log(
    //   translateXLength,
    //   itemSize * wrapSizeValue + gutterSize * (wrapSizeValue - 1) + translateXLength,
    //   itemsLength * itemSize + gutterSize * (itemsLength - 1)
    // )
    if (anchorCursor <= 0) {
      status.isFirst = true
    }
    if (anchorCursor >= itemsLength - wrapSizeValue - 1) {
      status.isLast = true
    }
    if (activeIndex <= 0) {
      status.isActiveFirst = true
    }
    if (activeIndex >= itemsLength - 1) {
      status.isActiveLast = true
    }
    return status
  }, [
    anchorCursor,
    wrapSizeValue,
    itemsLength,
    itemsToShow,
    activeIndex,
    direction,
    translateXLength,
    itemSize,
    gutterSize,
  ])

  // 滚动至
  const scrollTo = useCallback(
    async (index = 0, mode = 'indexes') => {
      return new Promise((resolve) => {
        let cursorIndex = index
        if (mode === 'turn') {
          cursorIndex = index * wrapSizeValue + anchorCursor
        }
        if (mode === 'step') {
          cursorIndex = Math.max(activeIndex + index, 0)
        }
        const newActiveIndex = Math.max(0, Math.min(cursorIndex, itemsLength - 1))
        const newCursorIndex = Math.max(0, Math.min(cursorIndex, itemsLength - wrapSizeValue))
        setAnchorCursor(newCursorIndex)
        setActiveIndex(newActiveIndex)
        setDirection(newActiveIndex > activeIndex ? 1 : newActiveIndex < activeIndex ? -1 : 0)
        setTimeout(() => {
          resolve(containerRef)
        }, 0)
      })
    },
    [itemsLength, wrapSizeValue, anchorCursor, activeIndex]
  )

  // key生成方式
  const keyidValue = useCallback(
    (item, index) => {
      if (keyid === 'index') {
        return index
      }
      if (typeof keyid === 'function') {
        return keyid(item, index)
      }
      return item[keyid]
    },
    [keyid]
  )
  useScrollBarActive(containerRef)

  useImperativeHandle(ref, () => ({
    // getRoundItems,
    scrollTo,
    turnStatus,
    dom: containerRef.current,
    setActiveIndex: (index) => setActiveIndex(index),
  }))

  useMount(onMount)

  return (
    <div className="reponsiveLayoutWrap thinScroller" ref={containerRef}>
      {prefixAppend && prefixAppend(itemsToShow)}
      <ul className={classnames(['reponsiveLayout', className])} style={mainStyle}>
        {itemsToShow.map((item, index) => {
          const style = { ...itemStyle }
          let aniClass = []
          if (index === activeIndex) {
            aniClass.push('reponsiveLayoutActiveItem')
          }
          // 左右滑动时使用
          if (moveType === 'translateX') {
            if (index < anchorCursor) {
              if (inClass) {
                aniClass.push(inClass)
              } else if (inStyle) {
                Object.keys(inStyle).reduce((s, key) => {
                  s[key] = inStyle[key]
                  return s
                }, style)
              } else {
                style.transformOrigin = 'left center'
                style.transform = 'scale(0.5)'
                style.opacity = '0'
              }
            }
            if (index > anchorCursor + wrapSizeValue - 1) {
              if (outClass) {
                aniClass.push(outClass)
              } else if (outStyle) {
                Object.keys(outStyle).reduce((s, key) => {
                  s[key] = outStyle[key]
                  return s
                }, style)
              } else {
                style.transformOrigin = 'right center'
                style.transform = 'scale(0.5)'
                style.opacity = '0'
              }
            }
          }
          return (
            <li
              ref={(el) => containerChildRef.current.push(el)}
              style={style}
              className={classnames(['reponsiveLayoutItem', ...aniClass])}
              key={keyidValue(item, index)}
              onClick={() => {
                setActiveIndex(index)
              }}
            >
              {children(item, index, {
                isLastItem: isLastItem(index),
                itemsLength,
              })}
            </li>
          )
        })}
      </ul>
      {listAppend && listAppend(itemsToShow)}
    </div>
  )
}

export default React.memo(forwardRef(Responsive))
